import { Link } from "react-router-dom"
import "./style.css"
import { ReactNode } from "react"
import { ReactComponent as LoadingSvg } from "./loading_icon.svg"

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "outlined" | "secondary"
  minWidth?: number | "inherit"
  startIcon?: ReactNode
  loading?: boolean
}
export const Button = ({
  startIcon,
  variant = "primary",
  minWidth = 170,
  loading,
  ...props
}: IButtonProps) => {
  return (
    <button
      type="button"
      {...props}
      className={`button button-${variant} ${props.className}`}
      style={{ minWidth: minWidth, ...props.style }}
    >
      {loading && (
        <div className="loading_bar">
          <LoadingSvg />
        </div>
      )}
      {startIcon}
      {props.children}
    </button>
  )
}

interface IArrowButtonProps {
  title: ReactNode
  to: string
}

export const ArrowButton = ({ title, to }: IArrowButtonProps) => {
  return (
    <Link to={to} className="arrow-button button button-secondary">
      {title}
      <svg width="131" height="19" viewBox="0 0 131 19" fill="none">
        <line
          x1="1.68386"
          y1="10.459"
          x2="125.996"
          y2="10.0068"
          stroke="#694D97"
          strokeWidth="2"
        ></line>
        <path
          d="M116.688 16.459V16.459C119.629 12.8892 123.823 10.5769 128.412 9.99402L128.687 9.95898L128.412 9.92394C123.823 9.34106 119.629 7.02872 116.688 3.45898V3.45898"
          stroke="#694D97"
          strokeWidth="2"
        ></path>
      </svg>
    </Link>
  )
}

interface IFaqBackButtonProps {
  to: string | number
  title: ReactNode
}

export const FaqBackButton = ({ to, title }: IFaqBackButtonProps) => {
  return (
    <Link className="button button-secondary button__faq-back" to={to as string}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="23"
        viewBox="0 0 15 23"
        fill="none"
      >
        <g clipPath="url(#clip0_252_10260)">
          <path
            d="M10.8003 4.94824L4.20024 11.4998L10.8003 18.0513"
            stroke="black"
            strokeWidth="3"
            strokeLinecap="round"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_252_10260">
            <rect width="23" height="15" fill="white" transform="matrix(0 1 -1 0 15 0)"></rect>
          </clipPath>
        </defs>
      </svg>
      {title}
    </Link>
  )
}
