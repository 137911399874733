export const FAQ_DATA: { title: string; content: string }[] = [
  {
    title: "Ok, guys, I got you, I want to have a debit card. what should I do first?",
    content:
      "You want to get a card? It’s easy! Just log into your account and go to the ”Card” section. Follow the instructions and place an order. In case you do not have a Wizarbit account, the system will ask you to register. We made the process easy because we value your time.",
  },
  {
    title:
      "I want to have a card but I don't think the identity verification is necessary. so what should I do?",
    content:
      "Verification is a mandatory step. Your safety is our priority. You can upload the required document at your Wizarbit cabinet. It won’t take long!",
  },
  {
    title: "I would like to know what currencies are supported.",
    content: "You can exchange your BTC, ETH, LTC, USDT, USDT-TRC20 to EUR.",
  },
  {
    title: "Ok, it is clear with the currency. what about the countries?",
    content:
      "Our exchange services are offered in more than 100 countries! The cards are available in the EU (25 + countries). We keep expanding!",
  },
  {
    title:
      "Could you please explain clearly what is the difference between a debit card and a credit card?",
    content:
      "When you use a credit card, you are borrowing money. Generally, when you use a debit card, you are spending money you have already loaded onto the card in advance. You top up your Wizarbit card by exchanging your crypto to EUR.",
  },
  {
    title: "I want to use your card around the world. is it possible?",
    content:
      "Sure! You can use your Wizarbit debit card as a regular ATM card worldwide. You can top it exchanging your crypto to fiat and pay anywhere.",
  },
]
