import React, { useEffect } from "react"
import { ExClient, selectUser, useStoreClient } from "@usher/pe-client-front-end-lib"
import Button from "@/Components/Button"
import { VerificationsWallets } from "@/Components/Client/Verifications/Wallets"
import { VerificationsVerify } from "@/Components/Client/Verifications/Verify"
import "./index.sass"

export const Verifications = ({ path }) => {
  const { address, city, state, zip, country_code } = useStoreClient(selectUser)
  const setTypes = useStoreClient((state) => state.setUploadedTypes)
  useEffect(() => {
    const check = async () => {
      const { data, success } = await ExClient.checkVerifications()
      if (success) setTypes(data)
    }
    check()
    //eslint-disable-next-line
  }, [setTypes])

  return (
    <div className="Verifications">
      <div className="Box VerifyAccountBoxTop VerifyAccount">
        <div className="VerifyAccount__Title">ACCOUNT VERIFICATION</div>
        <div className="VerifyAccount__Description">
          Verify your account to enjoy all the benefits Wizarbit has to offer.
        </div>
        <div className="VerifyAccount__Items">
          <div className="VerifyAccount__Item-Wrapper VerifyAccount__Item-Wrapper--Large">
            <div className="VerifyAccount__Item Account__Item--Large">
              <div className="VerifyAccount__Item-Info">
                <div className="VerifyAccount__Item-Title">Personal information</div>
                <div className="VerifyAccount__Item-Desc">
                  {address} / {city}, {country_code} / {state}, {zip}
                </div>
              </div>
              <Button color="Blue" to={`${path}/settings`} className="VerifyAccount__Item-Button">
                Edit
              </Button>
            </div>
          </div>
          <VerificationsVerify />
        </div>
      </div>
      <VerificationsWallets />
    </div>
  )
}
