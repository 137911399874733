import { Image } from "@/Components/common/Image"
import { FAQ_DATA } from "./data"
import { Link } from "react-router-dom"

export const CardFaq = () => {
  return (
    <section className="card__faq">
      <div className="container">
        <div className="faq__wrap">
          <h2 className="h2 faq__title">wizarbit card FAQ</h2>
          <ul className="faq-list">
            {FAQ_DATA.map((faq, index) => (
              <li key={index} className="faq-list__item">
                <div className="faq-list__box">
                  <h5 className="faq-list__title">{faq.title}</h5>
                  <a className="faq-list__link" href="#">
                    <Image src="arrow-link.svg" />
                  </a>
                </div>

                <div className="faq-list__text">{faq.content}</div>
              </li>
            ))}
          </ul>
          <div>
            <h4 className="faq-bottom__title">Have more questions?</h4>
            <div className="faq-bottom__text">
              Find the answers in the MultiPass <Link to="/faq">Help centre</Link> or contact our
              team
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
