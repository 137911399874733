import React from "react"
import { FormProvider } from "react-hook-form"
import { useSettingsAddressForm } from "@usher/pe-client-front-end-lib"
import { CtrlInput } from "@/Components/ctrl/Input"
import { CtrlSelectCountry } from "@/Components/ctrl/SelectCountry"

export const SettingsAddress = () => {
  const { formMethods, onSubmit } = useSettingsAddressForm()

  return (
    <FormProvider {...formMethods}>
      <form className="Box Settings__Box" onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="Settings__Box-Title">Billing address</div>
        <div className="Form">
          <div className="Form__Row Form__Row--Sm Form__Row--mb-40">
            <div className="Form__Col Form__Col--Sm">
              <CtrlSelectCountry disabled required />
            </div>
            <div className="Form__Col Form__Col--Sm">
              <CtrlInput disabled name="state" label="State" required />
            </div>
          </div>
          <div className="Form__Row Form__Row--Sm Form__Row--mb-40">
            <div className="Form__Col Form__Col--Sm">
              <CtrlInput disabled name="city" label="City" required />
            </div>
            <div className="Form__Col Form__Col--Sm">
              <CtrlInput disabled name="zip" label="Zip Code" required />
            </div>
          </div>
          <div className="Form__Row Form__Row--Sm">
            <div className="Form__Col Form__Col--Sm">
              <CtrlInput disabled name="address" label="Street Address" required />
            </div>
          </div>
          {/* <div className="Form__Row Form__Row--Sm">
            <div className="Form__Col Form__Col--Sm">
              <Button type="submit" color="Red">
                Save
              </Button>
            </div>
          </div> */}
        </div>
      </form>
    </FormProvider>
  )
}
