import { IOption } from "@/types"
import { ApiData } from "@usher/pe-client-front-end-lib"
import { useCallback, useEffect, useState } from "react"
import { BSelect, IBSelectProps } from "../BSelect"
import { Controller, useFormContext, UseControllerOptions } from "react-hook-form"

export const CountrySelect = <T extends string = string>(
  props: Omit<IBSelectProps<T>, "options"> & { name?: string; required?: boolean }
) => {
  const context = useFormContext()
  const rules: UseControllerOptions["rules"] = {}
  const [options, setOptions] = useState<IOption<string>[]>([])
  const fetchCountries = useCallback(async () => {
    const options = (await ApiData.getSelectorList("country")).map((item) => ({
      value: item.value,
      label: item.key,
    }))
    setOptions(options)
  }, [])
  useEffect(() => {
    fetchCountries()
  }, [])
  if (props.required) rules.required = { message: "This field is required", value: true }
  const error = context.errors[props.name]
  return (
    <Controller
      control={context.control}
      name={props.name}
      rules={rules}
      render={({ onChange, value }) => (
        <BSelect
          size="large"
          searchable
          {...props}
          value={(options.find((option) => option.value === value) as IOption<T>) ?? null}
          inputProps={{ placeholder: "Select" }}
          options={options as IOption<T>[]}
          onSelect={(option) => onChange(option.value)}
          errorText={error != null ? error.message : undefined}
        />
      )}
    />
  )
}
