export const LEGAL_LINKS: [string, string][] = [
  ["AML & KYC", "aml-policy"],
  ["Terms & Conditions", "terms-of-use"],
  ["Privacy policy", "privacy-policy"],
  ["Refund and Return Policy", "refund-policy"],
  ["Risk Disclosure", "risk-disclocure"],
  ["Card Terms and Conditions", "card-terms"],
  ["Card Framework Agreement", "card-framework"],
  ["Wizarbit's Terms of Use for Cards", "card-use-terms"],
]
