export const LoadingAnimation = ({ text }: { text: string | number }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="74px" height="74px">
        <g fill="none" stroke="#4177c0" strokeLinecap="round" strokeWidth="1.5">
          <path
            strokeDasharray="2 4"
            strokeDashoffset="6"
            d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21"
          >
            <animate
              attributeName="stroke-dashoffset"
              dur="0.6s"
              repeatCount="indefinite"
              values="6;0"
            />
          </path>
          <path
            strokeDasharray="30"
            strokeDashoffset="30"
            fill="none"
            d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3"
          >
            <animate
              fill="freeze"
              attributeName="stroke-dashoffset"
              begin="0.1s"
              dur="0.3s"
              values="30;0"
            />
          </path>
        </g>
      </svg>
      <span style={{ position: "absolute" }}>{text}</span>
    </div>
  )
}
