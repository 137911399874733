import "./index.sass"
import { Link, NavLink, useLocation } from "react-router-dom"
import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { Logo } from "@/Components/Logo"
import { HeaderAuth } from "@/Components/Header/auth"
import { Msg } from "../Msg"

export const Header = ({ theme }) => {
  const [isOpen, setBurgerState] = useState(false)
  const location = useLocation()

  useEffect(() => {
    document.body.style.overflow = "unset"
    setBurgerState(false)
  }, [location])

  const handleMenuShow = () => {
    setBurgerState(!isOpen)
    !isOpen ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "unset")
  }

  return (
    <header
      className={classnames("Header", {
        "Header--Open": isOpen,
        [`Header--${theme}`]: theme,
      })}
    >
      <div className="Container">
        <nav className="Header__Nav">
          <ul className="Header__List">
            <li className="Header__Item Logo">
              <Link to="/" className="Header__Logo">
                <Logo invert={theme === "White"} />
              </Link>
            </li>
            <li className="Header__Item">
              <NavLink
                className="Header__Link"
                to="/exchange"
                activeClassName="Header__Link--Active"
              >
                <Msg id="buy_crypto" />
              </NavLink>
            </li>
            <li className="Header__Item">
              <NavLink className="Header__Link" to="/rates" activeClassName="Header__Link--Active">
                <Msg id="rates" />
              </NavLink>
            </li>
            <li className="Header__Item">
              <NavLink className="Header__Link" to="/faq" activeClassName="Header__Link--Active">
                <Msg id="faq" />
              </NavLink>
            </li>
            <li className="Header__Item">
              <NavLink className="Header__Link" to="/card" activeClassName="Header__Link--Active">
                <Msg id="cards" />
              </NavLink>
            </li>
          </ul>
          <HeaderAuth />
        </nav>
        <div className="Header__Mobile">
          <Link to="/" className="Header__Logo">
            <Logo invert={theme === "White"} />
          </Link>
          <span className="Header__Burger" onClick={handleMenuShow}>
            <span />
            <span />
            <span />
          </span>
        </div>
      </div>
    </header>
  )
}
