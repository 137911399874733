import "./index.sass"
import React from "react"
import Select, { components } from "react-select"
import classnames from "classnames"
import { IconChevronDown } from "@/Images/Icons"

export const SelectSingle = (props) => {
  const { options, type, theme, value, handleChange, emptyLabel, label, placeholder, ...rest } =
    props

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <IconChevronDown />
      </components.DropdownIndicator>
    )
  }

  return (
    <>
      {label && <span className="SelectSingle__Label">{label}</span>}
      {emptyLabel && <span className="SelectSingle__Label">&nbsp;</span>}
      <Select
        className={classnames(
          "SelectSingle",
          `SelectSingle--${rest.disabled ? "disabled" : ""}`,
          `SelectSingle--${type}`,
          `SelectSingle--${theme}`
        )}
        components={{ DropdownIndicator, Placeholder }}
        placeholder={placeholder}
        isSearchable={false}
        value={value /* && { label: value, value: value }*/}
        classNamePrefix="SelectSingle"
        onChange={(e) => handleChange(e, rest.name)}
        options={options || []}
        isDisabled={rest.disabled}
        {...rest}
      />
    </>
  )
}
