import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle"
import { ToastContainer } from "react-toastify"
import { CookieConsent } from "react-cookie-consent"
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom"
import { setConfigUrls, useApp, useLang } from "@usher/pe-client-front-end-lib"
import { Home } from "@/Pages/Home"
import { Card } from "@/Pages/CardNew"
import { Auth } from "@/Pages/Auth"
import { Exchange } from "@/Pages/Exchange"
import { Faq } from "@/Pages/Faq"
import { Rates } from "@/Pages/RatesNew"
import { AboutUs } from "@/Pages/AboutUsNew"
import ScrollToTop from "@/Helpers/ScrollToTop"
import { ContactUs } from "@/Pages/ContactUsNew"
import { Limits } from "@/Pages/LimitsNew"
import { Countries } from "@/Pages/Countries"
import { Dashboard } from "@/Pages/Dashboard"
import "react-toastify/dist/ReactToastify.css"
import { NotFound } from "@/Pages/NotFound"
import { Security } from "@/Pages/Security"
import "./index.css"
import { LANGUAGES } from "@/constants/LangConstants"
import { TopUpCard } from "./Pages/TopUpCard"
import { IntlProvider } from "react-intl"
// import { WizarbitTerms } from "./Pages/WizarbitTerms"
import { Msg } from "./Components/Msg"
import { LoadingPage } from "./Pages/LoadingPage"
import { UI } from "./Pages/UI"
import { Legal } from "./Pages/Legal"
import { AffiliateProgram } from "./Pages/AffiliateProgram"
import { Suspense, lazy } from "react"
import { ReferalProgram } from "./Pages/ReferalProgram"

const AvoidFraud = lazy(async () => await import("@/Pages/AvoidFraudNew"))

setConfigUrls({
  PAY: process.env.REACT_APP_PATH_PAY,
  SECURE: process.env.REACT_APP_PATH_SECURE,
})

export const App = () => {
  useApp()
  const { locale, messages, basename } = useLang({ languages: LANGUAGES })
  if (locale === "") return <LoadingPage />
  return (
    <IntlProvider locale={locale} messages={messages}>
      <ToastContainer autoClose={4000} hideProgressBar />
      <CookieConsent
        buttonClasses="Button Button--Blue Button--Mini"
        buttonStyle={{ margin: 15 }}
        disableButtonStyles
      >
        <Msg id="cookie" />
      </CookieConsent>
      <BrowserRouter basename={basename}>
        <Suspense fallback="Loading">
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/(login|register|recovery|new_password)" component={Auth} />
            <Route path="/card" component={Card} />
            <Route path="/top-up-card" component={TopUpCard} />
            <Route path="/exchange" component={Exchange} />
            <Route path="/faq" component={Faq} />
            <Route path="/avoid-fraud" component={AvoidFraud} />
            <Route path="/rates" component={Rates} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/affiliate-program" component={ReferalProgram} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/rates-limits" component={Limits} />
            <Route path="/covered-countries" component={Countries} />
            <Route path="/client" component={Dashboard} />
            <Route path="/secure" component={Security} />
            <Route path="/legal" component={Legal} />
            <Route path="/ui" component={UI} />
            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </IntlProvider>
  )
}
