import React from "react"
import classnames from "classnames"
import { useLocation } from "react-router-dom"
import "./index.sass"
import { ImageLogo, ImageLogoDark } from "@/Images/Images"

function getLogo(pathname) {
  const darkPages = [
    "/",
    "/about-us",
    "/rates-limits",
    "/affiliate-program",
    "/covered-countries",
    "/card",
  ]

  if (darkPages.includes(`${pathname}`) && pathname.indexOf("/exchange") < 0) return ImageLogo

  return ImageLogoDark
}

export const Logo = ({ invert }) => {
  const location = useLocation()
  return (
    <div className={classnames("Logo", { "Logo--Invert": invert })}>
      <img src={getLogo(location.pathname)} alt="WizarBit" />
    </div>
  )
}

function getLogoFooter(pathname) {
  const darkPages = ["/", "/about-us", "/rates-limits", "/covered-countries"]

  if (darkPages.includes(`${pathname}`) && pathname.indexOf("/exchange") < 0) return ImageLogo

  return ImageLogoDark
}

export const LogoFooter = ({ invert }) => {
  const location = useLocation()

  return (
    <div className={classnames("Logo", { "Logo--Invert": invert })}>
      <img src={getLogoFooter(location.pathname)} alt="WizarBit" />
    </div>
  )
}
