import { Image } from "@/Components/common/Image"

export const HowWorks = () => {
  return (
    <section className="work">
      <div className="container">
        <div className="work__wrap">
          <h2 className="h2 work__title">How it works</h2>
          <ul className="work-list">
            <li className="work-list__item">
              <Image src="work-5.png" />
              <h4 className="work-list__title">Get wizarbit card</h4>
              <div className="work-list__text">
                EMV chip, PIN code and all the specifications provide fast payments, as well as
                security up to the highest world standards.
              </div>
            </li>
            <li className="work-list__item">
              <Image src="work-6.png" alt="" />
              <h4 className="work-list__title">Get wizarbit card</h4>
              <div className="work-list__text">
                EMV chip, PIN code and all the specifications provide fast payments, as well as
                security up to the highest world standards.
              </div>
            </li>
            <li className="work-list__item">
              <Image src="work-7.png" alt="" />
              <h4 className="work-list__title">Get wizarbit card</h4>
              <div className="work-list__text">
                EMV chip, PIN code and all the specifications provide fast payments, as well as
                security up to the highest world standards.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
