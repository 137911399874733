import React from "react"
import { CardDetailsHeader } from "../Header"
import { Card } from "@/Pages/Dashboard/Orders/Card"

export const CardTransactions = () => {
  return (
    <>
      <div className="main-content_one-card">
        <CardDetailsHeader />
      </div>

      <div className="main-content">
        <Card withoutTab />
      </div>
    </>
  )
}
