import { Layout } from "@/Layout"
import { Container } from "@/Layout/Container"
import { Route, Switch } from "react-router-dom"
import { FaqMain } from "./FaqMain"
import { faq_list } from "../Faq_old/list"
import { FaqList } from "./FaqList"
import { FaqView } from "./FaqView"
const list = faq_list()
export const Faq = () => {
  return (
    <Layout className="faq">
      <Container className="faq__container">
        <Switch>
          <Route path="/faq" exact component={() => <FaqMain list={list} />} />
          <Route
            exact
            path="/faq/:prefix"
            render={({ match }) => {
              const detail_list = list.filter((item) => item.prefix === match.params.prefix)[0]
              return <FaqList data={detail_list} match={match} />
            }}
          />

          <Route
            exact
            path="/faq/:prefix/:id"
            render={({ match }) => {
              const detail_list = list.filter((item) => item.prefix === match.params.prefix)[0]
              const detail = detail_list.list.filter(
                (item) => item.id === parseInt(match.params.id)
              )[0]
              return <FaqView detail={detail} match={match} />
            }}
          />
        </Switch>
      </Container>
    </Layout>
  )
}
