import React, { useEffect, useState } from "react"
import { selectUser, useSettingsPhoto, useStoreClient } from "@usher/pe-client-front-end-lib"
import { Modal } from "@/Components/Modal"
import { IconUserDashBoard } from "@/Images/Icons"
import Button from "@/Components/Button"
import { UPLOAD_STYLES } from "@/constants/CommonConstants"
import { AvatarCrop } from "./Crop"

const { activeStyle, acceptStyle, rejectStyle } = UPLOAD_STYLES

export const AsideUserAvatar = () => {
  const { avatar } = useStoreClient(selectUser)
  const { upload, isUploading, getRootProps, getInputProps, style } = useSettingsPhoto({
    activeStyle,
    acceptStyle,
    rejectStyle,
  })

  const [cropFile, setCropFile] = useState<File>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => setCropFile(null), [isModalOpen])

  const onSave = async () => {
    const success = await upload(cropFile)
    if (success) {
      setCropFile(null)
      setIsModalOpen(false)
    }
  }

  return (
    <>
      <div className="DashboardAside__Avatar" onClick={() => setIsModalOpen(true)}>
        {avatar ? <img src={avatar} alt="avatar" /> : <IconUserDashBoard />}
      </div>
      <Modal size="Avatar" isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)}>
        <div className="UploadAvatarModal">
          <div className="UploadAvatarTitle">Upload your account avatar</div>
          {avatar ? (
            <AvatarCrop file={cropFile} setFile={setCropFile} />
          ) : (
            <div {...getRootProps({ style, className: "UploadAvatarImage" })}>
              <input {...getInputProps({ disabled: isUploading })} />
              <IconUserDashBoard width="60" height="60" />
            </div>
          )}
          {isUploading && (
            <div style={{ textAlign: "center", padding: "13px 0px 14px 0px" }}>Uploading..</div>
          )}
          {cropFile && !isUploading && (
            <div>
              <Button color="Red" onClick={onSave} style={{ marginRight: 10 }}>
                Save
              </Button>
              <Button color="Dark" onClick={() => setCropFile(null)}>
                Cancel
              </Button>
            </div>
          )}
          {avatar && !isUploading && !cropFile && (
            <div {...getRootProps({ style })}>
              <input {...getInputProps({ disabled: isUploading })} />
              <Button color="Red">Upload New</Button>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}
