import React from "react"
import { types, useBaseUploadForm } from "@usher/pe-client-front-end-lib"
import Button from "@/Components/Button"
import { COMPANY_EMAIL, UPLOAD_STYLES } from "@/constants/CommonConstants"

const { activeStyle, acceptStyle, rejectStyle } = UPLOAD_STYLES

const onSuccess = (fileName: string, isAllVerified: boolean) => {
  if (!isAllVerified)
    return (
      <>
        {fileName} is uploaded.
        <br />
        Your documents have been sent to Verification Department. For successful KYC procedure,
        please, submit all the required documents. If you have a question, please contact us via
        email {COMPANY_EMAIL}
      </>
    )
  return (
    <>
      {fileName} is uploaded.
      <br />
      Your documents have been sent to Verification Department, please wait for the answer from the
      team manager or contact us via email {COMPANY_EMAIL}
    </>
  )
}

export const VerifyUpload = ({ type, isAllVerified }: { type: string; isAllVerified: boolean }) => {
  const {
    error,
    isUploaded,
    isUploading,
    onUpload,
    files,
    getRootProps,
    getInputProps,
    onDelete,
    style,
  } = useBaseUploadForm({
    type,
    onSuccess: (filename) => onSuccess(filename, isAllVerified),
    multiple: true,
    activeStyle,
    acceptStyle,
    rejectStyle,
  })
  return (
    <div className="UploadDocumentsModal">
      <div className="UploadDocumentsModal__Title">{types.uploadModalInfo[type]["title"]}</div>
      <div className="UploadDocumentsModal__Desc">{types.uploadModalInfo[type]["info"]}</div>
      {error !== "" && <div className="UploadDocumentsModal__Error">{error}</div>}
      <div className="UploadDocumentsModal__Content">
        <div className="UploadDocumentsModal__Info">
          <ul className="UploadDocumentsModal__List">
            <li className="UploadDocumentsModal__Item">Applicable formats: JPG, PNG and PDF.</li>
            <li className="UploadDocumentsModal__Item">High quality photos.</li>
            <li className="UploadDocumentsModal__Item">Up-to-dateness.</li>
            <li className="UploadDocumentsModal__Item">Size between 50 KB and 8 MB.</li>
          </ul>
        </div>
        <div className="UploadDocumentsModal__Dropzone">
          {isUploading && <div style={{ textAlign: "center" }}>Uploading...</div>}
          {isUploaded && <div className="alert alert-success">File has been uploaded!</div>}
          {!isUploading && !isUploaded && (
            <>
              <div {...getRootProps({ className: "UploadItem", style })}>
                <input {...getInputProps()} />
                <div className="UploadItem__Head">
                  <div className="UploadItem__Title">UPLOAD OR SELECT A FILE FROM YOUR DEVICE</div>
                </div>
                <div className="UploadItem__Bottom">
                  Drag file here to upload or <span>select a file</span> from your device
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {!isUploading && !isUploaded && (
        <>
          <div className="UploadDocumentsModal__Items">
            {files.map((file, key) => (
              <div key={key} className="UploadDocumentsModal__Items__Item">
                <img
                  src={file.type === "application/pdf" ? "/img/pdfFileType.svg" : file.preview}
                  alt=""
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onDelete(file)
                  }}
                >
                  X
                </button>
              </div>
            ))}
          </div>
          <div className="UploadDocumentsModal__Button">
            <Button color="Red" onClick={onUpload}>
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
