import React from "react"
import classnames from "classnames"
import { useHistory } from "react-router-dom"
import { components, useExchangeFormMini, useStoreExchange } from "@usher/pe-client-front-end-lib"
import Button from "@/Components/Button"
import { ExchangeBlockItem } from "@/Components/ExchangeBlock/ExchangeBlockItem"
import { HowCalculated } from "@/Components/HowCalculated"
import "./index.sass"
import { useIntl } from "react-intl"
import { gtag } from "@/Helpers/gTag"
import shallow from "zustand/shallow"

interface ExchangeBlockProps {
  modifier: string
  size?: string
  names?: {
    left: string
    right: string
    button: string
    calculated: string
  }
  inputTheme?: string
  buttonModifier: string
}

export const ExchangeBlock = ({
  modifier,
  size,
  names,
  inputTheme,
  buttonModifier,
}: ExchangeBlockProps) => {
  const history = useHistory()
  const [selectFiat, selectCrypto] = useExchangeFormMini()
  const { fetchPrices, amount, crypto, currencytype } = useStoreExchange(
    (state) => ({
      fetchPrices: state.fetchPrices,
      amount: state.fiatAmount,
      crypto: state.cryptoAmount,
      currencytype: state.fiatCurrency,
    }),
    shallow
  )
  const intl = useIntl()
  return (
    <div
      className={classnames("Exchange-Block", `Exchange-Block--${modifier}`, {
        [`Exchange-Block--${size}`]: size,
      })}
    >
      <div className="Exchange-Block__Inner">
        <ExchangeBlockItem
          selector={selectFiat}
          label={names && names.left ? names.left : intl.formatMessage({ id: "asset8" })}
          inputTheme={inputTheme}
          selectTheme={inputTheme === "White" ? "White" : "Blue"}
        />
        <ExchangeBlockItem
          selector={selectCrypto}
          label={names && names.right ? names.right : intl.formatMessage({ id: "asset9" })}
          inputTheme={inputTheme}
          selectTheme={inputTheme === "White" ? "White" : "Orange"}
        />
        <Button
          onClick={() => {
            gtag("event", "click_exchange", {
              input_amount: amount,
              input_currency: "EUR",
              output_amount: crypto,
              output_currency: currencytype,
            })()
            history.push("/exchange")
          }}
          color={buttonModifier}
        >
          {names && names.button ? names.button : intl.formatMessage({ id: "exchange" })}
        </Button>
      </div>
      <div className="Exchange-Block__Info">
        <components.Timer
          onUpdate={fetchPrices}
          wrapWithTag=""
          text={intl.formatMessage({ id: "recalculate" })}
        />
        <br />
        <HowCalculated
          text={
            names && names.calculated
              ? names.calculated
              : intl.formatMessage({ id: "explore_more" })
          }
        />
      </div>
    </div>
  )
}
