import React from "react"
import Input from "@/Components/Input"
import "./index.sass"

export default class InputWithSelect extends React.Component {
  render() {
    const { children, label, value, handleChange, ...rest } = this.props

    return (
      <Input
        label={label}
        isnumbersonly="true"
        value={value}
        handleChange={handleChange}
        withSelect={true}
        {...rest}
      >
        {children}
      </Input>
    )
  }
}
