import React from "react"
import { useAvailableCountries } from "@usher/pe-client-front-end-lib"
import { Layout } from "@/Layout/OldLayout"
import { IconUSA, IconEarth, IconEuro } from "@/Images/Images"
import { Nav } from "@/Components/Nav"
import Button from "../../Components/Button"
import { CountriesItem } from "./CountriesItem"
import "./index.sass"

export const Countries = () => {
  const { regions, countries, onRegionChange, isShowMore, onShowMore, regionId } =
    useAvailableCountries()
  const countriesNav = [{ id: 0, name: "All countries" }, ...regions]

  return (
    <Layout headerTheme={"Dark"} footerTheme={"Dark"} withHeader={true}>
      <div className="Countries">
        <div className="Container">
          <h1 className="Countries__Title Title Title--H1">
            <span>
              Our services are provided in
              <br /> more than 160 countries
            </span>
            <div className="Countries__Pulse-Icon Countries__Pulse-Icon--Earth Hero__Coin Hero__Coin--ltc">
              <img src={IconEarth} alt="img" />
            </div>
            <div className="Countries__Pulse-Icon Countries__Pulse-Icon--China Hero__Coin Hero__Coin--usa">
              <img src={IconUSA} alt="img" />
            </div>
            <div className="Countries__Pulse-Icon Countries__Pulse-Icon--Euro Hero__Coin Hero__Coin--ltc">
              <img src={IconEuro} alt="img" />
            </div>
          </h1>
          {regions.length > 0 && (
            <>
              <div className="Country__Tabs">
                <Nav
                  theme="Red"
                  data={countriesNav}
                  handleClick={onRegionChange}
                  activeId={regionId}
                />
              </div>
              <div className="Country__Table">
                <div className="Table">
                  <div className="Table__Col-Group Country__Table-Col-Group">
                    <div className="Table__Col" style={{ width: "25%" }} />
                    <div className="Table__Col" style={{ width: "5%" }} />
                    <div className="Table__Col" style={{ width: "15%" }} />
                    <div className="Table__Col" style={{ width: "30%" }} />
                    <div className="Table__Col" style={{ width: "20%" }} />
                  </div>
                  <div className="Table__Head Country__Table-Head">
                    <div className="Table__Row">
                      <div className="Table__Cell">Country</div>
                      <div className="Table__Cell">Buy</div>
                      <div className="Table__Cell">Sell</div>
                      <div className="Table__Cell">Payment methods</div>
                      <div className="Table__Cell">Debit card issuing</div>
                    </div>
                  </div>
                  <div className="Country__Table-Body Table__Body">
                    {countries.map((item, key) => (
                      <CountriesItem key={key} data={item} />
                    ))}
                  </div>
                </div>
              </div>
              {!isShowMore && (
                <div className="Country__More">
                  <Button color="Red" onClick={onShowMore}>
                    Show more
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}
