import { Button } from "@/Components/Control/Button"
import { Image } from "@/Components/common/Image"
import { gtag } from "@/Helpers/gTag"
import { types, useExchangeStep5 } from "@usher/pe-client-front-end-lib"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"

const { STATUS_COMPLETED, STATUS_ERROR, STATUS_PENDING } = types.ExTransactionStatus
const statusIconPrefixes = {
  [STATUS_COMPLETED]: "success",
  [STATUS_ERROR]: "error",
  [STATUS_PENDING]: "pending",
}

export const StatusCheck = () => {
  const { result, fetchStatus, onBuyAgain, onTryAgain, loading } = useExchangeStep5({
    history: useHistory(),
  })
  const { status_id, status } = result

  useEffect(() => {
    gtag("event", "exchange_step5_sc")()
  }, [])

  const onButtonClick = () => {
    switch (status_id) {
      case STATUS_COMPLETED:
        onBuyAgain()
        break
      case STATUS_ERROR:
        onTryAgain()
        break
      default:
        fetchStatus()
    }
  }
  const prefix =
    status_id in statusIconPrefixes
      ? statusIconPrefixes[status_id as keyof typeof statusIconPrefixes]
      : "pending"

  let buttonText = "Reload"
  if (status_id === STATUS_COMPLETED) buttonText = "Buy again"
  if (status_id === STATUS_ERROR) buttonText = "Try again"

  return (
    <div className="status_check">
      <div className="status-check__inner">
        <h4 className="status-check__title">Your current exchange status</h4>
        <Image src={`status_${prefix}.svg`} />
        <div className="status-check__text">{status.toUpperCase()}</div>
        {status_id != null && (
          <Button loading={buttonText === "Reload" ? loading : false} onClick={onButtonClick}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  )
}
