import React, { useState } from "react"
import classnames from "classnames"
import { IconCheckWhite, IconChevronDownSm, IconTimesRed } from "@/Images/Icons"
import { Image } from "@/Components/common/Image"
import "./index.sass"

const getIcon = (data) => {
  const region = data.region_name.replace(/\s+/g, "_").toLowerCase()
  const country = data.name.replace(/\s+/g, "-").toLowerCase()
  return <Image src={`countries/${region}/${country}.svg`} alt={data.name} />
}

export const CountriesItem = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div
        className={classnames("Table__Row CountriesItem__Row", {
          "CountriesItem__Row--Open": isOpen,
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="Table__Cell Table__Cell--Dark CountriesItem__Cell">
          <span className="CountriesItem__Label">Country</span>
          <span className="CountriesItem__Icon">{getIcon(data)}</span>
          <span>{data.name}</span>
          <IconChevronDownSm className="CountriesItem__Arrow" />
        </div>
        <div className="Table__Cell Table__Cell--Dark CountriesItem__Cell--MobileSm CountriesItem__Cell">
          <span className="CountriesItem__Label">Buy</span>
          {data.is_enabled ? <IconCheckWhite /> : <IconTimesRed />}
        </div>
        <div className="Table__Cell Table__Cell--Dark CountriesItem__Cell--MobileSm CountriesItem__Cell">
          <span className="CountriesItem__Label">Sell</span>
          {data.is_enabled ? <IconCheckWhite /> : <IconTimesRed />}
        </div>
        <div className="Table__Cell Table__Cell--Dark CountriesItem__Cell">
          <span className="CountriesItem__Label">Payment methods</span>
          {data.is_enabled ? "Credit Card, Bank Transfer" : ""}
        </div>
        <div className="Table__Cell Table__Cell--Dark CountriesItem__Cell">
          <span className="CountriesItem__Label">Debit card issuing</span>
          {data.is_enabled ? "Coming Soon!" : <IconTimesRed />}
        </div>
      </div>
      <div className="Table__Separator CountriesItem__Separator" />
    </>
  )
}
