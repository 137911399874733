import React from "react"
import shallow from "zustand/shallow"
import { ExchangeSelector, useStoreExchange } from "@usher/pe-client-front-end-lib"
import InputWithSelect from "@/Components/InputWithSelect"
import FormSelect from "@/Components/FormSelect"

interface ExchangeBlockItemProps {
  selector: ExchangeSelector
  label: string
  inputTheme?: string
  selectTheme?: string
  className?: string
}

export const ExchangeBlockItem = ({
  selector,
  label,
  inputTheme,
  selectTheme,
  className = "Exchange-Block__Input",
}: ExchangeBlockItemProps) => {
  const { amount, currency, setAmount, setCurrency, currencies } = useStoreExchange(
    selector,
    shallow
  )
  return (
    <div className={className}>
      <InputWithSelect
        label={label}
        theme={inputTheme}
        value={amount}
        name="spend_input"
        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)}
      >
        <div className="Form-Input__Select">
          <FormSelect
            value={{ label: currency, value: currency }}
            type={"Simple"}
            theme={selectTheme}
            options={currencies
              .filter((curr) => curr !== "USD")
              .map((opt) => ({ label: opt, value: opt }))}
            handleChange={(val: any) => setCurrency(val.value)}
            name="spend_select"
          />
        </div>
      </InputWithSelect>
    </div>
  )
}
