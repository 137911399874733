import React from "react"
import { absUrl } from "@/Helpers/UrlHelper"

type Props = {
  title?: string
  title_id?: string
  children?: JSX.Element | JSX.Element[]
  showBuyCoins?: boolean
}

export const PageTitle = ({ title, title_id, children, showBuyCoins = false }: Props) => {
  return (
    <div className="main-content_top-line">
      <div className="main-content_left">
        <div className="div-h1">
          {title}
          {/* {title_id && <FormattedMessage id={title_id}/>} */}
        </div>
        {children && <div className="main-content_btn">{children}</div>}
      </div>
      {/* {showBuyCoins && (
        <a href={absUrl('/exchange')} className="btn-reset btn-sq btn-white no-decorate">
          <FormattedMessage id="buy_coins"/>
        </a>
      )} */}
    </div>
  )
}
