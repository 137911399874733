import {
  AgreeAgeCheck,
  AgreeRefundCheck,
  AgreeRiskCheck,
  AgreeTermCheck,
} from "@/Pages/Auth/Register/Checkboxes"
import { ExchangeDocumentUpload } from "../components/ExchangeDocumentUpload"
import { FormWrapper } from "@/Components/Form"
import { useExchangeStep3 } from "@usher/pe-client-front-end-lib"
import { useHistory } from "react-router-dom"
import "./style.sass"
import { Msg } from "@/Components/Msg"
import { WDescription } from "@/Components/Description"
import { Button } from "@/Components/Control/Button"
import { SkipModal } from "./SkipModal"

export const DocumentVerification = () => {
  const history = useHistory()
  const {
    formMethods,
    showSkip,
    onSkipConfirm,
    setShowSkip,
    onSubmit,
    passport,
    photo,
    setStep3,
    isDefaultShop,
    isDocsUploaded,
    loading,
  } = useExchangeStep3({ history })

  if (isDefaultShop && isDocsUploaded)
    return (
      <div style={{ marginRight: 30 }}>
        <WDescription>
          <h2>
            <Msg id="success" />
          </h2>
          <br />
          <Msg id="dv16" />
        </WDescription>
      </div>
    )
  return (
    <FormWrapper methods={formMethods} onSubmit={onSubmit}>
      <div className="document_verification">
        <h4 className="transaction__title">Upload documents to verify your account</h4>
        <ExchangeDocumentUpload
          file={passport}
          type="passport"
          marginBottom={35}
          tooltip={
            <>
              <p style={{ marginBottom: 20 }}>
                The passport or ID card provided for verification must contain the following
                information:
              </p>
              <ul>
                <li>· Country that issued the document</li>
                <li>· The serial number of the document proving the identity</li>
                <li>· Date of issue</li>
                <li>· Gender</li>
                <li>· Surname</li>
                <li>· Name</li>
                <li>· Date of birth</li>
                <li>· Place of birth</li>
                <li>· Date of expiry</li>
              </ul>
            </>
          }
          setFile={(file) => setStep3({ passport: file })}
        />
        <ExchangeDocumentUpload
          file={photo}
          type="photo"
          marginBottom={35}
          tooltip={
            <>
              <p style={{ marginBottom: 20 }}>
                The copy of the document you are uploading must include the following:
              </p>
              <ul>
                <li>· The country in which the document was issued</li>
                <li>· The serial # of the document</li>
                <li>· The date of the document issue</li>
                <li>· Your surname & name</li>
                <li>· The date and place of your birth</li>
                <li>· The document expiry date</li>
              </ul>
            </>
          }
          setFile={(file) => setStep3({ photo: file })}
        />
        <div className="verification-example">
          <ul className="verification-example__list">
            <li className="verification-example__item">
              <span>1</span>
              Supported formats JPG, PNG and PDF
            </li>
            <li className="verification-example__item">
              <span>3</span>
              Provide coloured high-resolution images (at least 20 dpi);
            </li>
            <li className="verification-example__item">
              <span>2</span>
              Documents should be up-to date
            </li>
            <li className="verification-example__item">
              <span>4</span>
              Documents must be at least 50KB and no more than 8MB
            </li>
          </ul>
        </div>

        {!isDefaultShop && (
          <SkipModal isShow={showSkip} onConfirm={onSkipConfirm} setShowSkip={setShowSkip} />
        )}
        <div className="policy">
          <AgreeTermCheck />
          <AgreeRiskCheck />
          <AgreeRefundCheck />
          <AgreeAgeCheck />
        </div>
        <div className="exchange__actions">
          <Button loading={loading} type="submit" variant="primary">
            Exchange
          </Button>
          <Button variant="outlined" onClick={() => history.push("/")}>
            Cancel
          </Button>
        </div>
      </div>
    </FormWrapper>
  )
}
