import React, { useState } from "react"
import { FormProvider } from "react-hook-form"
import { useSettingsPasswordForm } from "@usher/pe-client-front-end-lib"
import { CtrlInput } from "@/Components/ctrl/Input"
import { IconEye } from "@/Images/Icons"
import Button from "@/Components/Button"

function PasswordFiled({ name, label }: { name: string; label: string }) {
  const [visible, setVisible] = useState(false)
  return (
    <div className="Form__Col Form__Col--Sm">
      <CtrlInput
        type={visible ? "test" : "password"}
        label={label}
        name={name}
        autoComplete="new-password"
        required
      >
        <span className="Form-Input__Icon" onClick={() => setVisible(!visible)}>
          <IconEye />
        </span>
      </CtrlInput>
    </div>
  )
}

export const SettingsPassword = () => {
  const { formMethods, onSubmit } = useSettingsPasswordForm()

  return (
    <FormProvider {...formMethods}>
      <form className="Box Settings__Box" onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="Settings__Box-Title">CHANGE PASSWORD</div>
        <div className="Form">
          <div className="Form__Row Form__Row--Sm Form__Row--mb-40">
            <PasswordFiled name="password" label="Password" />
            <PasswordFiled name="password2" label="Repeat Password" />
          </div>
          <div className="Form__Row Form__Row--Sm">
            <div className="Form__Col Form__Col--Sm">
              <Button type="submit" color="Red">
                Change
              </Button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
