import React from "react"
import Button from "@/Components/Button"

type Props = {
  onClick: () => void
  verified: number
  isUploaded: { uploadedTypes?: Record<string, string>; section: number }
  children: React.ReactNode
}

export const VerifyItem = ({ onClick, verified, children, isUploaded }: Props) => {
  return (
    <div className="VerifyAccount__Item-Wrapper">
      <div className="VerifyAccount__Item">
        <div className="VerifyAccount__Item-Info">
          <div className="VerifyAccount__Item-Title">
            {children}
            {verified ? (
              <span className="VerifyAccount__Item-Status VerifyAccount__Item-Status--Verify">
                Verified
              </span>
            ) : (
              <span className="VerifyAccount__Item-Status VerifyAccount__Item-Status--NoVerify">
                Not verified
              </span>
            )}
          </div>
        </div>
        {isUploaded.uploadedTypes != null && (
          <>
            {isUploaded.uploadedTypes[isUploaded.section] === "1" ? (
              <Button color="Blue" className="VerifyAccount__Item-Button" onClick={onClick}>
                Edit
              </Button>
            ) : (
              <Button color="Red" className="VerifyAccount__Item-Button" onClick={onClick}>
                Upload and Verify
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}
