import { Route, Switch, useRouteMatch } from "react-router-dom"
import { Exchange } from "./Exchange"
import { Card } from "./Card"

export const OrdersPage = () => {
  const path = useRouteMatch().path

  return (
    <div className="main-content">
      <Switch>
        <Route exact path={path} component={Exchange} />
        <Route path={`${path}/card`}>
          <Card />
        </Route>
      </Switch>
    </div>
  )
}
