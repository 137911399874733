import React, { useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { ApiData, getValue4Select } from "@usher/pe-client-front-end-lib"
import { SelectSingle } from "@/Components/SelectSingle"
import { useIntl } from "react-intl"

interface SelectCountryProps {
  name?: string
  required?: boolean
  rules?: {}
  label?: string
  theme?: "Dark" | "Light"
  disabled?: boolean
}

export const CtrlSelectCountry = ({
  name = "country_code",
  required = false,
  rules,
  label,
  theme = "Dark",
  ...rest
}: SelectCountryProps) => {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState([])
  const { control, errors } = useFormContext()

  useEffect(() => {
    const fetchOptions = async () => {
      const options = (await ApiData.getSelectorList("country")).map((item) => ({
        value: item.value,
        label: item.key,
      }))
      setOptions(options)
      setIsLoading(false)
    }
    fetchOptions().then()
  }, [])

  if (required) rules = { ...rules, required: "This field is required." }
  // if (errors[name]) className += ' is-invalid'

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ onChange, value, name }) => (
          <SelectSingle
            label={label ?? intl.formatMessage({ id: "country" })}
            placeholder={intl.formatMessage({ id: "select" })}
            options={options}
            value={getValue4Select(options, value)}
            handleChange={(val: any) => onChange(val.value)}
            name={name}
            isLoading={isLoading}
            isSearchable={true}
            theme={theme}
            {...rest}
          />
        )}
      />
      {errors[name] && <div className="invalid-feedback">{errors[name].message}</div>}
    </>
  )
}
