import "./Base/index.sass"
import React from "react"
import { Header } from "@/Components/Header"
import Footer from "@/Components/Footer"

export const Layout = ({ children, withHeader, headerTheme, footerTheme }) => {
  return (
    <>
      <div className="Layout_Wrapper">
        {withHeader && <Header theme={headerTheme} />}
        <div className="wrapper">
          <main className="main">{children}</main>
        </div>
      </div>
      <Footer theme={footerTheme} />
    </>
  )
}
