import React from "react"
import { DashboardChart } from "@/Components/Client/Dashboard/Chart"
import { DashboardPersonalInfo } from "@/Components/Client/Dashboard/PersonalInfo"
import { ImageCryptocurrencies } from "@/Images/Images"
import Button from "@/Components/Button"
import "./index.sass"
import { DashboardInfoBar } from "../RightSiderbar"

export const Toolboxes = () => {
  return (
    <div style={{ display: "flex", width: "100%", gap: 20 }}>
      <div className="Dashboard_Chart">
        <div className="Box ToolboxesChartBox">
          <DashboardChart />
        </div>
        <div className="ToolboxesBottom">
          <div className="Box ToolboxesInfoBox">
            <DashboardPersonalInfo />
          </div>
          <div className="ToolboxesBuy">
            <img
              className="ToolboxesBuy__Image"
              src={ImageCryptocurrencies}
              alt="Cryptocurrencies"
            />
            <div className="ToolboxesBuy__Title">BUY CRYPTOCURRENCY</div>
            <div className="ToolboxesBuy__Desc">
              Just use your credit card.
              <br /> No deposit needed.
            </div>
            <Button className="ToolboxesBuy__Button" to="/exchange" color="White">
              Buy coins
            </Button>
          </div>
        </div>
      </div>
      <div style={{ width: "auto" }}>
        <DashboardInfoBar />
      </div>
    </div>
  )
}
