import React, { useState } from "react"
import classnames from "classnames"
import { NavLink, useRouteMatch } from "react-router-dom"
import { ExClient, selectUser, useStoreClient } from "@usher/pe-client-front-end-lib"
import { IconChevronDownSm } from "@/Images/Icons"
import { AsideUserAvatar } from "./Avatar"

export const AsideUser = () => {
  const path = useRouteMatch().path
  const { first_name, last_name } = useStoreClient(selectUser)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="DashboardAside__User">
      <AsideUserAvatar />
      <div
        className={classnames("DashboardAside__UserInfo", {
          "DashboardAside__UserInfo--Open": isOpen,
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="DashboardAside__UserName">
          {first_name} {last_name}
        </div>
        <IconChevronDownSm className="DashboardAside__Arrow" />
        <div className="DashboardAside__UserSettings">
          <NavLink
            className="DashboardAside__UserLink"
            activeClassName="DashboardAside__UserLink--Active"
            to={`${path}/settings`}
          >
            Settings
          </NavLink>
          <span className="DashboardAside__UserLink" onClick={async () => await ExClient.logout()}>
            Log out
          </span>
        </div>
      </div>
    </div>
  )
}
