import React from "react"
import { Link } from "react-router-dom"
import classnames from "classnames"
import { IconLt, IconMc, IconPci, IconVisaBg, IconUk } from "@/Images/Icons"
import { Icon3Ds } from "@/Images/Images"
import { LogoFooter } from "@/Components/Logo"
import { Social } from "@/Components/Social"
import { COMPANY_EMAIL, COMPANY_PHONE_LT, COMPANY_PHONE_UK } from "@/constants/CommonConstants"
import "./index.sass"
import { Msg } from "../Msg"

const Footer = ({ theme }) => (
  <footer
    className={classnames("Footer", {
      [`Footer--${theme}`]: theme,
    })}
  >
    <div className="Container">
      <div className="Footer__Top">
        <Link to="/" className="Footer__Logo">
          <LogoFooter invert={theme === "White"} />
        </Link>
        <div className="Footer__Socials">
          <Social invert={theme === "White"} href="https://facebook.com/wizarbit">
            <svg className="Social__Icon" aria-hidden="true" viewBox="0 0 320 512">
              <path
                fill="currentColor"
                d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
              />
            </svg>
          </Social>
          <Social invert={theme === "White"} href="https:///twitter.com/wizarbit">
            <svg className="Social__Icon" aria-hidden="true" viewBox="0 0 512 512">
              <path
                fill="currentColor"
                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
              />
            </svg>
          </Social>
          <Social invert={theme === "White"} href="https://instagram.com/wizarbit">
            <svg
              className="Social__Icon"
              aria-hidden="true"
              focusable="false"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              />
            </svg>
          </Social>
        </div>
      </div>
      <div className="Footer__Center">
        <div className="Footer__Col">
          <div className="Footer__Item Footer__Label">
            <Msg id="footer5" />
          </div>
          <Link className="Footer__Item Footer__Link" to="/exchange">
            <Msg id="footer1" />
          </Link>
          <Link className="Footer__Item Footer__Link" to="/rates">
            <Msg id="footer2" />
          </Link>
          {/* <Link className="Footer__Item Footer__Link" to="/affiliate-program">Affiliate Program</Link> */}
        </div>
        <div className="Footer__Col">
          <div className="Footer__Item Footer__Label">
            <Msg id="footer6" />
          </div>
          <Link className="Footer__Item Footer__Link" to="/about-us">
            <Msg id="footer3" />
          </Link>
          <Link className="Footer__Item Footer__Link" to="/contact-us">
            <Msg id="footer4" />
          </Link>
          <Link className="Footer__Item Footer__Link" to="/card">
            <Msg id="cards" />
          </Link>
        </div>
        <div className="Footer__Col">
          <div className="Footer__Item Footer__Label">
            <Msg id="footer7" />
          </div>
          <Link className="Footer__Item Footer__Link" to="/aml-policy">
            <Msg id="amlkyc" />
          </Link>
          <Link className="Footer__Item Footer__Link" to="/terms-of-use">
            <Msg id="footer8" />
          </Link>
          <Link className="Footer__Item Footer__Link" to="/privacy-policy">
            <Msg id="privacy_title" />
          </Link>
          <Link className="Footer__Item Footer__Link" to="/refund-policy">
            <Msg id="refund_title" />
          </Link>
          <Link className="Footer__Item Footer__Link" to="/risk-disclocure">
            <Msg id="risk_nav_title" />
          </Link>
          <Link className="Footer__Item Footer__Link" to="/avoid-fraud">
            <Msg id="footer10" />
          </Link>
          <Link className="Footer__Item Footer__Link" to="/card-terms">
            <Msg id="card_terms_title" />
          </Link>
          <Link className="Footer__Item Footer__Link" to="/card-framework">
            <Msg id="card_framework_title" />
          </Link>
          <Link className="Footer__Item Footer__Link" to="/card-use-terms">
            <Msg id="card_use_title" />
          </Link>
        </div>
        <div className="Footer__Col">
          <div className="Footer__Item Footer__Label">
            <Msg id="help" />
          </div>
          <Link className="Footer__Item Footer__Link" to="/faq">
            <Msg id="faq" />
          </Link>
          <Link className="Footer__Item Footer__Link" to="/rates-limits">
            <Msg id="rates_limits" />
          </Link>
          <Link className="Footer__Item Footer__Link" to="/covered-countries">
            <Msg id="covered" />
          </Link>
        </div>
        <div className="Footer__Col Footer__Col--AlignRight">
          <a href={`tel:${COMPANY_PHONE_UK}`} className="Footer__TelCol">
            <IconUk />
            {COMPANY_PHONE_UK}
          </a>
          <a href={`tel:${COMPANY_PHONE_LT}`} className="Footer__TelCol">
            <IconLt />
            {COMPANY_PHONE_LT}
          </a>
          <div className="Footer__Label">
            <Msg id="email" />
          </div>
          <a href={`mailto:${COMPANY_EMAIL}`} className="Footer__Link">
            {COMPANY_EMAIL}
          </a>
        </div>
      </div>
      <div className="Footer__Bottom">
        <div className="Footer__Bottom-Left">
          <div className="Footer__Payments">
            <IconMc className="Footer__Payment" />
            <IconVisaBg className="Footer__Payment Footer__Payment--VISA" />
            <img src={Icon3Ds} className="Footer__Payment" alt="footer-img" />
            <IconPci className="Footer__Payment" />
          </div>
          <div className="Footer__Copy">
            <Msg id="copyright" /> © {new Date().getFullYear()} WizarBit. <Msg id="all_right" />.
          </div>
        </div>
        <div className="Footer__Bottom-Right">
          <div className="Footer__Info">
            <p>
              <Msg id="uab" vars={{ number: 306098538 }} />
              <br />
              <Msg id="asa" />
              <br />
              <Msg id="address_reg" />: Perkūnkiemio g. 13-91, LT-12114, Vilnius, Lithuania
              <br />
              <Msg id="located_at" />: Konstitucijos av. 21A, LT-08130 Vilnius, Lithuania.
            </p>
          </div>
        </div>
      </div>
      <div className="Footer__Disclaimer">
        <Msg id="footer_disclaimer" />
      </div>
    </div>
  </footer>
)

export default Footer
