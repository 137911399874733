import { selectUser, useStoreClient } from "@usher/pe-client-front-end-lib"
import { NavLink, useRouteMatch } from "react-router-dom"
import { IconDashboard, IconUserVerify, OrdersIcon, CardIcon } from "@/Images/Images"

export const AsideLinks = () => {
  const { card_active } = useStoreClient(selectUser)
  const path = useRouteMatch().path
  const activeClassName = "DashboardAside__Link--Active"

  return (
    <div className="DashboardAside__Links">
      <NavLink exact to={path} className="DashboardAside__Link" activeClassName={activeClassName}>
        <img src={IconDashboard} alt="icon" />
        <span>Toolboxes</span>
      </NavLink>
      <NavLink
        to={`${path}/exchange`}
        className="DashboardAside__Link "
        activeClassName={activeClassName}
      >
        <img src={OrdersIcon} alt="icon" />
        <span>Orders</span>
      </NavLink>
      {card_active && (
        <NavLink
          to={`${path}/cards`}
          className="DashboardAside__Link "
          activeClassName={activeClassName}
        >
          <img src={CardIcon} alt="icon" />
          <span>Cards</span>
        </NavLink>
      )}

      <NavLink
        to={`${path}/verifications`}
        className="DashboardAside__Link"
        activeClassName={activeClassName}
      >
        <img src={IconUserVerify} alt="icon" />
        <span>Verifications</span>
      </NavLink>
    </div>
  )
}
