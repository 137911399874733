import React from "react"
import { useVerifyWalletForm } from "@usher/pe-client-front-end-lib"
// import Button from "@/Components/Button"

export const VerificationsWallets = () => {
  const { cryptoList } = useVerifyWalletForm()

  return (
    <div className="Box VerifyAccountBoxBottom VerifyAccount">
      <div className="VerifyAccount__Title">PLEASE SET YOUR WALLET</div>
      <div className="VerifyAccount__Description">
        Please, indicate the wallet address. It will be verified within one working day.
      </div>
      <div className="VerifyAccount__Fields">
        {cryptoList.map(({ name, wallet }, key) => (
          <div key={key} className="VerifyAccount__Field-Wrapper">
            <div className="VerifyAccount__Field">
              <div className="Form-InputBox Form-InputBox--Dark">
                <span className="Form-Input__Label">{name}</span>
                <input
                  type="text"
                  className="Form-Input Form-Input--Dark"
                  defaultValue={wallet}
                  disabled
                />
              </div>
              {/*<Button color="Blue" size="Small" className="VerifyAccount__Item-Button">Edit</Button>*/}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
