import React from "react"
import { useHistory } from "react-router-dom"
import NumberFormat from "react-number-format"
import { Rate, useRatesFiat } from "@usher/pe-client-front-end-lib"
import { IconTriangleDown, IconTriangleUp } from "@/Images/Icons"

function CurrItem(value: Rate) {
  if (!value) return null

  const { symbol, rate, change } = value
  return (
    <div className="DashboardAside__CurrItem">
      <div className="DashboardAside__CurrText">
        {symbol}
        {change < 0 ? <IconTriangleDown /> : <IconTriangleUp />}
      </div>
      <div className="DashboardAside__CurrValue">
        <NumberFormat value={rate} displayType="text" thousandSeparator={true} suffix=" USD" />
      </div>
    </div>
  )
}

export const AsideCurrencies = () => {
  const { rates } = useRatesFiat({ history: useHistory() })
  const rateBTC = rates.find((rate) => rate.symbol === "BTC")
  const rateETH = rates.find((rate) => rate.symbol === "ETH")
  const rateLTC = rates.find((rate) => rate.symbol === "LTC")

  return (
    <div className="DashboardAside__Currencies">
      {CurrItem(rateBTC)}
      {CurrItem(rateETH)}
      {CurrItem(rateLTC)}
    </div>
  )
}
