import { Layout } from "@/Layout"
import "./style.css"
import { LIMITS_ITEMS } from "./limits"
import { LimitBlock } from "./LimitBlock"
export const Limits = () => {
  console.log(LIMITS_ITEMS)
  return (
    <Layout darkTheme>
      <main className="main rates-limits">
        <section className="rates">
          <div className="container">
            <div className="rates__wrap">
              <h1 className="h2 rates__title">Rates and limits</h1>
              <ul className="rates-list">
                {LIMITS_ITEMS.map((limit) => (
                  <LimitBlock limit={limit} />
                ))}
                <li className="rates-list-item">
                  <p>* Additional commission may be charged from the payment system</p>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}
