import React from "react"
import { FormProvider } from "react-hook-form"
import { useSettingsInfoForm, VALID_NAME } from "@usher/pe-client-front-end-lib"
import { CtrlInput } from "@/Components/ctrl/Input"
import { CtrlRadio } from "@/Components/ctrl/Radio"
// import Button from "@/Components/Button"

export const SettingsInfo = () => {
  const { formMethods, onSubmit } = useSettingsInfoForm()

  return (
    <FormProvider {...formMethods}>
      <form className="Box Settings__Box" onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="Settings__Box-Title">Add personal information</div>
        <div className="Form">
          <div className="Form__Row Form__Row--Sm Form__Row--Sm Form__Row--mb-40">
            <div className="Form__Col Form__Col--Sm">
              <CtrlInput
                disabled
                name="first_name"
                label="First Name"
                required
                refProps={VALID_NAME}
              />
            </div>
            <div className="Form__Col Form__Col--Sm">
              <CtrlInput
                disabled
                name="last_name"
                label="Last Name"
                required
                refProps={VALID_NAME}
              />
            </div>
          </div>
          <div className="Form__Row Form__Row--Sm Form__Row--mb-40">
            <div className="Form__Col Form__Col--Sm">
              <CtrlInput type="email" name="email" label="Email" disabled />
            </div>
            <div className="Form__Col Form__Col--Sm">
              <CtrlInput name="phone" label="Phone number" disabled />
            </div>
          </div>
          <div className="Form__CheckboxLabel">Sex</div>
          <div style={{ display: "flex", marginTop: 10, justifyContent: "flex-start" }}>
            <div className="Form__Col--Sm Form__Col--Auto-Width" style={{ marginRight: 25 }}>
              <CtrlRadio disabled name="sex" value="1" required>
                Male
              </CtrlRadio>
            </div>
            <div className="Form__Col--Sm Form__Col--Auto-Width">
              <CtrlRadio disabled name="sex" value="0" required>
                Female
              </CtrlRadio>
            </div>
          </div>
          {/* <div className="Form__Row Form__Row--Sm">
            <div className="Form__Col Form__Col--Sm">
              <Button type="submit" color="Red">
                Save
              </Button>
            </div>
          </div> */}
        </div>
      </form>
    </FormProvider>
  )
}
