import React from "react"
import ReactTooltip from "react-tooltip"
import { useSettingsPhoto } from "@usher/pe-client-front-end-lib"
import { IconInfo } from "@/Images/Icons"
import { UPLOAD_STYLES } from "@/constants/CommonConstants"

const { activeStyle, acceptStyle, rejectStyle } = UPLOAD_STYLES

export const SettingsPhoto = () => {
  const { isUploading, getRootProps, getInputProps, style } = useSettingsPhoto({
    activeStyle,
    acceptStyle,
    rejectStyle,
  })

  return (
    <div className="Box Settings__Box">
      {isUploading && <div style={{ textAlign: "center" }}>Uploading...</div>}
      {!isUploading && (
        <>
          <div {...getRootProps({ className: "UploadItem", style })}>
            <input {...getInputProps()} />
            <div className="UploadItem__Head">
              <div className="UploadItem__Title">
                Upload your account
                <br /> avatar
              </div>
              <span className="UploadItem__Tooltip" data-for="tooltip-avatar" data-tip="">
                <IconInfo className="UploadItem__Icon" />
                <ReactTooltip
                  className="TooltipSimple"
                  id="tooltip-avatar"
                  place={"bottom"}
                  effect="solid"
                  arrowColor="#5BA1FF"
                  textColor="#fff"
                  backgroundColor="#5BA1FF"
                  offset={{ left: 105 }}
                >
                  Applicable formats: JPG, PNG.
                </ReactTooltip>
              </span>
            </div>
            <div className="UploadItem__Bottom">
              Drag file here to upload or <span>select a file</span> from <br />
              your device
            </div>
          </div>
        </>
      )}
    </div>
  )
}
