import { Container } from "@/Layout/Container"
import "./style.css"
import { ArrowButton } from "@/Components/Control/Button"

export const Help = () => {
  return (
    <section className="home_section help">
      <Container>
        <h2 className="h2 help__h2">If you need Help</h2>
        <div className="help__row">
          <div className="help__cell">
            <h3 className="help__title">Support</h3>
            <p className="help__describe">You rest while we support!</p>
            <ArrowButton title="Need help" to="/contact-us" />
          </div>
          <div className="help__cell">
            <h3 className="help__title">Blog</h3>
            <p className="help__describe">
              Read company news and take part in the development of the industry
            </p>
            <ArrowButton title="Go" to="/" />
          </div>
          <div className="help__cell">
            <h3 className="help__title">FAQ</h3>
            <p className="help__describe">
              If you need help, just write to us. Our support is available
              <br />
              24/7
            </p>
            <ArrowButton title="Need help" to="/faq" />
          </div>
        </div>
      </Container>
    </section>
  )
}
