import { ReactNode, useMemo } from "react"
import "./style.css"
import { IListItem } from "@/Pages/Faq_old/list"
import { Msg } from "@/Components/Msg"
import { ArrowButton } from "@/Components/Control/Button"
import { Link } from "react-router-dom"

interface IFaqPaper extends Omit<React.HTMLAttributes<HTMLElement>, "title"> {
  title: ReactNode
  prefix: string
  list: IListItem[]
}

export const FaqPaper = ({ title, prefix, list, ...props }: IFaqPaper) => {
  const filtered = useMemo(() => list.filter((item) => !item?.hidden), [list])
  return (
    <div {...props} className={`faq__paper ${props.className ?? ""}`}>
      <div className="faq-content__box-title">
        <h4 className="faq-title">
          {title}
          {list.length && <span>{filtered.length}</span>}
        </h4>
      </div>
      <ul className="faq-list">
        {filtered.slice(0, 5).map((item) => (
          <li key={item.id} className="faq-list__item">
            <Link className="faq-list__link" to={`/faq/${prefix}/${item.redirect ?? item.id}`}>
              <Msg id={item.title} />
            </Link>
          </li>
        ))}
      </ul>
      <ArrowButton title={<Msg id="see_all" />} to={`/faq/${prefix}`} />
    </div>
  )
}
