import { match } from "react-router-dom"
import { IListItem } from "../Faq_old/list"
import { FaqHeader } from "./components/FaqHeader"
import { ReactComponent as FaqLike } from "@/Images/icon_files/faq_like.svg"
import { ReactComponent as FaqDislike } from "@/Images/icon_files/faq_unlike.svg"
import { Msg } from "@/Components/Msg"

interface IFaqViewProps {
  detail: IListItem
  match: match<{ [x: string]: string }>
}
export const FaqView = ({ detail }: IFaqViewProps) => {
  return (
    <div className="faq__view">
      <FaqHeader back="/faq" />
      <div className="faq__list-paper view-paper">
        <div>
          <div className="faq-title">
            <Msg id={detail.title} />
          </div>
          {detail.date != null && <div className="faq-content__date small-date">{detail.date}</div>}
        </div>
        <div className="faq__view-content">{detail.content}</div>
        <div className="faq-bottom">
          <div className="faq-bottom__text">Did you find it helpful?</div>
          <div className="faq-bottom__icons">
            <a className="faq-bottom__icon" href="#">
              <FaqLike />
            </a>
            <a className="faq-bottom__icon" href="#">
              <FaqDislike />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
