import React from "react"
import { NavLink } from "react-router-dom"
import { useHeaderAuth } from "@usher/pe-client-front-end-lib"
import Button from "@/Components/Button"
import { LanguageSelect } from "../LanguageSelect"
import { Msg } from "../Msg"
import { gtag } from "@/Helpers/gTag"

export const HeaderAuth = () => {
  const { isAuthorized, onLogout } = useHeaderAuth()

  return (
    <ul className="Header__List">
      {isAuthorized ? (
        <>
          <li className="Header__Item">
            <LanguageSelect />
          </li>
          <li className="Header__Item Header__Login">
            <NavLink className="Header__Link" activeClassName="Header__Link--Active" to="/client">
              <Msg id="dashboard" />
            </NavLink>
          </li>
          <li className="Header__Item Header__Button">
            <Button onClick={onLogout} color="Red">
              <Msg id="logout" />
            </Button>
          </li>
        </>
      ) : (
        <>
          <li className="Header__Item">
            <LanguageSelect />
          </li>
          <li className="Header__Item Header__Login" onClick={gtag("event", "click_login")}>
            <NavLink className="Header__Link" activeClassName="Header__Link--Active" to="/login">
              <Msg id="dashboard_login" />
            </NavLink>
          </li>
          <li className="Header__Item Header__Button" onClick={gtag("event", "click_registration")}>
            <Button to="/register" color="Red">
              <Msg id="register" />
            </Button>
          </li>
        </>
      )}
    </ul>
  )
}
