import { Image } from "@/Components/common/Image"
import { ArrowButton } from "@/Components/Control/Button"

export const Cards = () => {
  return (
    <section className="cards">
      <div className="container">
        <div className="cards__wrap">
          <div className="cards__item">
            <Image src="cards-black.png" />
            <div className="cards__box">
              <h3 className="cards__title">Physical</h3>
              <div className="cards__slogan">
                All the advantages of products from these lines are not only preserved, but also
                multiplied in CryptoPro CSP 5.0: the list of supported platforms and algorithms is
                wider, performance is higher, and the user interface is more convenient.
              </div>
            </div>
            <ul className="cards-list">
              <li className="cards-list__item">1-7 business day delivery</li>
              <li className="cards-list__item">Online and in-store payments</li>
              <li className="cards-list__item">ATM withdrawals</li>
              <li className="cards-list__item">Contactless payments</li>
              <li className="cards-list__item">Both Chip and Magnetic stripe</li>
            </ul>
            <ArrowButton title="Get a card" to="#register" />
          </div>
          <div className="cards__item">
            <Image src="cards-purpl.png" />
            <div className="cards__box">
              <h3 className="cards__title">Virtual</h3>
              <div className="cards__slogan">
                All the advantages of products from these lines are not only preserved, but also
                multiplied in CryptoPro CSP 5.0: the list of supported platforms and algorithms is
                wider, performance is higher, and the user interface is more convenient.
              </div>
            </div>
            <ul className="cards-list">
              <li className="cards-list__item">1-7 business day delivery</li>
              <li className="cards-list__item">Online and in-store payments</li>
            </ul>
            <ArrowButton title="Get a card" to="/" />
          </div>
        </div>
      </div>
    </section>
  )
}
