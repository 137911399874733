import warningIcon from "@/Images/cards_images/warning.svg"
import { ECardType, useCurrencyTable, useTUCardStore } from "@usher/pe-client-front-end-lib"
import { useHistory } from "react-router-dom"

export const SelectPayment = () => {
  const history = useHistory()
  const { tableData } = useCurrencyTable(35)
  const { setCrypto, setDeposit, setFiat, cardType } = useTUCardStore((state) => ({
    setCrypto: state.setCrypto,
    setFiat: state.setFiat,
    setDeposit: state.setDeposit,
    cardType: state.cardTypeId,
  }))
  return (
    <div>
      <div className="warning-info" style={{ marginBottom: 20, fontSize: 14 }}>
        <img src={warningIcon} alt="" />
        <span>
          To complete the card issue, please pay 20 euros. In addition, pay a fee of 15 euros for
          the first month of using the card (total - 35 euros)
        </span>
      </div>
      {cardType === String(ECardType.Physical) && (
        <div className="warning-info" style={{ marginBottom: 30, fontSize: 14 }}>
          <img src={warningIcon} alt="" />
          <span>
            Attention! After ordering, the card can be activated and used for online payments, as
            well as added to Apple Pay and Google Pay, but for security reasons we do not recommend
            activating it until you receive it by physical post.{" "}
            <strong>Preliminary card delivery date 30/12/2023</strong>
          </span>
        </div>
      )}
      <div className="row">
        <div className="col-lg-12">
          <div className="octagon octagon-whit block-table-octagon">
            <div className="table-octagon_title">SELECT PAYMENT CURRENCY</div>
            <div className="table-octagon_content">
              <div className="table-octagon_header">
                <div className="table-octagon_current"></div>
                <div className="table-octagon_amount">Amount</div>
                <div className="table-octagon_completion">Completion</div>
                <div className="table-octagon_spend">You spend</div>
                <div className="table-octagon_btn"></div>
              </div>
              {tableData
                .filter((curr) => curr.symbol !== "BCH")
                .map(({ symbol, ...item }, key) => (
                  <div className="table-octagon_item">
                    <div className="table-octagon_current">
                      <img
                        src={`/img/coins/${
                          symbol.startsWith("USD") ? "usdt" : symbol.toLowerCase()
                        }.svg`}
                        alt=""
                      />
                      {symbol}
                    </div>
                    <div className="table-octagon_amount">{Number(item.amount).toFixed(2)} EUR</div>
                    <div className="table-octagon_completion">
                      from 6 Blockchain Network Confirmations
                    </div>
                    <div className="table-octagon_spend">
                      <span>{item.spend}</span> {symbol}
                    </div>
                    <div className="table-octagon_btn">
                      <button
                        className={`btn-reset deposit-btn btn-sq btn-long btn-red `}
                        onClick={() => {
                          setCrypto(symbol)
                          setDeposit("35")
                          setFiat("EUR")
                          history.push("/client/cards/order-card/expense/instructions")
                        }}
                      >
                        PAY
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
