import React from 'react'
import './index.sass'
import classnames from 'classnames'

export const Social = ({ invert = false, children, href }) => (
  <a className={classnames('Social', {
    'Social--Invert': invert
  })} target="_blank" href={href} rel="noreferrer">
    {children}
  </a>
)
