import React, { useEffect, useState } from "react"
import ReactCrop, { Crop } from "react-image-crop"
import { selectUser, useSettingsPhotoCrop, useStoreClient } from "@usher/pe-client-front-end-lib"
import { IconCrop } from "@/Images/Images"
import { Modal } from "@/Components/Modal"
import Button from "@/Components/Button"
import "react-image-crop/dist/ReactCrop.css"

export const AvatarCrop = ({ file, setFile }: { file: File; setFile: (file: File) => void }) => {
  const { avatar } = useStoreClient(selectUser)
  const { src, crop, cropFile, onApply, onCancel, onChange, onImageLoaded } = useSettingsPhotoCrop()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [completedCrop, setCompletedCrop] = useState<Crop>()

  useEffect(onCancel, [isModalOpen, onCancel])
  useEffect(() => setFile(cropFile), [cropFile, setFile])

  const onClick = () => {
    onApply()
    setIsModalOpen(false)
  }

  return (
    <>
      <div className="UploadAvatarImage" onClick={() => setIsModalOpen(true)}>
        <img src={file ? file["preview"] : avatar} alt="avatar" />
        <div className="UploadAvatarImageCrop">
          <img src={IconCrop} alt="crop" />
          Crop image
        </div>
      </div>
      <Modal size="AvatarCrop" isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)}>
        <div className="ReactCropWrap">
          <ReactCrop
            src={src}
            crop={crop}
            onChange={onChange}
            onImageLoaded={onImageLoaded}
            onComplete={(c) => setCompletedCrop(c)}
            minWidth={150}
            minHeight={150}
          />
        </div>
        {completedCrop && completedCrop.width > 0 && (
          <div style={{ marginTop: 20, marginBottom: -30, textAlign: "center" }}>
            <Button color="Red" onClick={onClick}>
              Apply
            </Button>
          </div>
        )}
      </Modal>
    </>
  )
}
