import React from "react"
import { SettingsInfo } from "@/Components/Client/Settings/Info"
import { SettingsAddress } from "@/Components/Client/Settings/Address"
import { SettingsPassword } from "@/Components/Client/Settings/Password"
import { SettingsPhoto } from "@/Components/Client/Settings/Photo"
import "./index.sass"

export const Settings = () => {
  return (
    <div className="Settings">
      <div className="Settings__Boxes">
        <div className="Settings__Box-Wrapper">
          <SettingsInfo />
        </div>
        <div className="Settings__Box-Wrapper">
          <SettingsAddress />
        </div>
      </div>
      <div className="Settings__Boxes">
        <div className="Settings__Box-Wrapper">
          <SettingsPassword />
        </div>
        <div className="Settings__Box-Wrapper">
          <SettingsPhoto />
        </div>
      </div>
    </div>
  )
}
