import { Layout } from "@/Layout"
import "./index.sass"
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom"
import { Logo } from "@/Components/Logo"
import { STEPS, Stepper } from "./components/Stepper"
import { Suspense, lazy } from "react"
import { BackButton } from "@/Components/Button/BackButton"
import { useTopUpCard } from "@usher/pe-client-front-end-lib"

const ByCrypto = lazy(async () => await import("./Steps/ByCrypto/index"))
const Confirmation = lazy(async () => await import("./Steps/Confirmation"))
const Instruction = lazy(async () => await import("./Steps/Instructions"))
const Status = lazy(async () => await import("./Steps/Status"))
const TransactionHash = lazy(async () => await import("./Steps/TransactionHash"))

export const TopUpCard = () => {
  const {
    currentStep,
    isAuthorized,
    isInitialized,
    path,
    isFirstPage,
    isLastPage,
    isInstructionPage,
    isHashPage,
  } = useTopUpCard(STEPS)
  const history = useHistory()
  if (!isInitialized) return null
  if (!isAuthorized) return <Redirect to="/login" />

  return (
    <Layout withHeader={false}>
      <div className="Page_Wrapper__Logo">
        <Link to="/" className="Header__Logo">
          <Logo invert />
        </Link>
      </div>
      <Suspense fallback="Loading...">
        <div className="Page_Wrapper">
          <div className="Container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="buy-steps__h2">Top Up Card</h2>
                <Stepper currentStep={currentStep} />
              </div>
            </div>
            <Switch>
              <Route exact path={`${path}/${STEPS[0].path}`} component={ByCrypto} />
              <Route path={`${path}/${STEPS[1].path}`} component={Confirmation} />
              <Route path={`${path}/${STEPS[2].path}/hash/:token`} component={TransactionHash} />
              <Route path={`${path}/${STEPS[2].path}`} component={Instruction} />
              <Route path={`${path}/${STEPS[3].path}/:token`} component={Status} />
            </Switch>
            {!isInstructionPage && (
              <div className="bottom-line-btn d-flex align-items-center">
                {!isLastPage && (
                  <BackButton
                    onClick={() =>
                      isHashPage ? history.replace("/top-up-card/by-crypto") : history.goBack()
                    }
                  />
                )}
                {!isFirstPage && !isLastPage && !isHashPage && (
                  <button
                    className="btn-reset btn-sq btn-long btn-red"
                    color="Blue"
                    onClick={() => history.push(STEPS[currentStep].path)}
                  >
                    NEXT
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </Suspense>
    </Layout>
  )
}
