import { Layout } from "@/Layout"
import { LEGAL_LINKS } from "./links"
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom"
import { Container } from "@/Layout/Container"
import { Suspense, lazy, useMemo, useState } from "react"
import "./style.css"
import "./style.sass"

const CardFramework = lazy(async () => await import("./Sections/CardFramework"))
const CardTerms = lazy(async () => await import("./Sections/CardTerms"))
const WizarbitTerms = lazy(async () => await import("./Sections/WizarbitTerms"))
const AmlPolicy = lazy(async () => await import("./Sections/Aml"))
const PrivacyPolicy = lazy(async () => await import("./Sections/Privacy"))
const RefundPolicy = lazy(async () => await import("./Sections/Refund"))
const RiskDisclosure = lazy(async () => await import("./Sections/Risk"))
const TermsOfUse = lazy(async () => await import("./Sections/TermsOfUse"))

export const Legal = () => {
  const [title, setTitle] = useState(LEGAL_LINKS[0][0])
  const { pathname } = useLocation()
  const currenctPath = useMemo(() => {
    const result = pathname.split("/").pop()
    setTitle(result)
    return result
  }, [pathname])
  return (
    <Layout mb={70}>
      <section className="legal">
        <Container>
          <div className="legal__wrap">
            <h1 className="legal__title">{title}</h1>
            <div className="legal__item">
              <div className="legal-nav">
                <h2 className="legal-nav__title">Legal information</h2>
                <ul className="legal-nav__list">
                  {LEGAL_LINKS.map(([label, path]) => (
                    <li className="legal-nav__item">
                      <Link
                        onClick={() => setTitle(label)}
                        className={`legal-nav__link${path === currenctPath ? " active" : ""}`}
                        to={path}
                      >
                        {label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="legal-content">
                <div className="legal-content__block">
                  <Suspense fallback="Loading Legal">
                    <Switch>
                      <Route path="/legal/aml-policy" component={AmlPolicy} />
                      <Route path="/legal/terms-of-use" component={TermsOfUse} />
                      <Route path="/legal/privacy-policy" component={PrivacyPolicy} />
                      <Route path="/legal/refund-policy" component={RefundPolicy} />
                      <Route path="/legal/risk-disclocure" component={RiskDisclosure} />
                      <Route path="/legal/card-terms" component={CardTerms} />
                      <Route path="/legal/card-framework" component={CardFramework} />
                      <Route path="/legal/card-use-terms" component={WizarbitTerms} />
                      <Route path="/" render={() => <Redirect to="/legal/aml-policy" />} />
                    </Switch>
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}
