export enum ThemeMode {
  Dark,
  Light,
}

export interface IOption<T = string> {
  label: string
  value: T
}

export enum Currencies {
  BTC = "btc",
  ETH = "eth",
  LTC = "ltc",
  USDT = "usdt",
  USDT20 = "usdt-trc20",
}
