import React from "react"
import { Link } from "react-router-dom"
import { ImageLogo } from "@/Images/Images"
import { AsideUser } from "@/Components/Client/Aside/User"
import { AsideLinks } from "@/Components/Client/Aside/Links"
import { AsideCurrencies } from "@/Components/Client/Aside/Currencies"
import { ExchangeBlock } from "@/Components/ExchangeBlock"
import "./index.sass"

export const DashboardAside = () => {
  return (
    <div className="DashboardAside">
      <div className="DashboardAside__Head">
        <Link to="/" className="DashboardAside__Head-Logo">
          <img src={ImageLogo} alt="WizarBit" />
        </Link>
        <AsideUser />
      </div>
      <div className="Dashboard__AsideContent">
        <div className="DashboardAside__Text">Your account</div>
        <AsideLinks />
        <div className="DashboardAside__Exchange">
          <ExchangeBlock
            names={{
              left: "Amount to Spend",
              right: "Coins to Receive",
              button: "Exchange",
              calculated: "How is the price calculated?",
            }}
            modifier={"Red"}
            buttonModifier={"White"}
            inputTheme={"White"}
            size="Compact"
          />
        </div>
        <AsideCurrencies />
      </div>
    </div>
  )
}
