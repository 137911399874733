import plus from "@/Images/cards_images/plus.svg"
import { SidebarAccountCards } from "./AccountCards"
import { Link } from "react-router-dom"
import { useActiveCards } from "@usher/pe-client-front-end-lib"
import { useState } from "react"

export const DashboardInfoBar = () => {
  const { activeCards, loading, isCardActive, isAllExist, cards } = useActiveCards()
  const [isOpen, setIsOpen] = useState(false)
  const balance = Number(activeCards?.[0]?.balance ?? "0")
  const isWaitExist = cards.some((card) => card.status.toLowerCase().includes("wait"))
  return (
    <>
      <div className={`main-content_sidebar account-sidebar ${isOpen ? "active" : ""}`}>
        <div
          className={`btn-account-sidebar${isOpen ? " is-active" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        />
        {!loading && (
          <div className="account-sidebar_btn">
            <Link
              to="/exchange/transaction-details"
              className="btn-reset btn-sq btn-long btn-blue no-decorate"
              style={{ width: isCardActive ? "auto" : "100%" }}
            >
              Buy crypto
            </Link>
            {isCardActive && (
              <Link
                to={
                  activeCards?.length === 0 && !isWaitExist
                    ? "/client/cards/order-card/expense"
                    : "/top-up-card/by-crypto"
                }
                className="btn-reset btn-sq btn-long btn-trpr input-brdr no-decorate"
              >
                {activeCards?.length === 0 && !isWaitExist ? "Create Card" : "Top-up card"}
              </Link>
            )}
          </div>
        )}
        {isCardActive && (
          <div className="account-sidebar_cards">
            <div
              className="table-orders_title"
              style={{ marginBottom: 8, display: "flex", fontSize: 14 }}
            >
              Card Balance:&nbsp;&nbsp;
              <div className={balance < 0 ? "text-danger" : balance > 0 ? "text-success" : ""}>
                {` ${balance.toFixed(2)}  EUR`}
              </div>
            </div>
            {!loading && !isAllExist && (
              <Link className="account-sidebar_cards-add" to="/client/cards/order-card/expense">
                <img src={plus} alt="" />
              </Link>
            )}
            <SidebarAccountCards cards={activeCards} fetching={loading} />
          </div>
        )}
        <div className="account-sidebar_footer">
          <div className="account-sidebar_footer-title">
            Do you know that you can make money with our affiliate program?
          </div>
          <div className="account-sidebar_footer-desc">
            Read more about <span>why to join the affiliate program</span>
          </div>
          <div className="account-sidebar_footer-line"></div>
          <div className="account-sidebar_footer-title">We’re here to help you!</div>
          <div className="account-sidebar_footer-desc">
            Ask any question or submit a ticket with an issue or manage requests. Our support team
            will get back to you by email.
          </div>
          <Link
            to="/faq"
            className="account-sidebar_footer-btn btn-reset btn-sq btn-white no-decorate input-brdr"
          >
            Get Support Now
          </Link>
        </div>
      </div>
    </>
  )
}
