import { useInstruction, components } from "@usher/pe-client-front-end-lib"
import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import { BackButton } from "@/Components/Button/BackButton"
import warningIcon from "@/Images/cards_images/warning.svg"
import { InstructionDialog } from "../components/InstructionDialog"
import { OnRampIcon } from "@/Images/Icons"

interface IInstructionsProps {
  isCreateCard?: boolean
}

const Instructions = ({ isCreateCard }: IInstructionsProps) => {
  const history = useHistory()
  const block = useRef(true)
  const [showDialog, setShowDialog] = useState("")

  const {
    fetchDepositAddress,
    createDeposit,
    createLoading,
    QRCode,
    crypto,
    deposit,
    fiat,
    wallet,
    totalAmount,
    copyToClipboard,
    loading,
    cryptoAmount,
    isOnrampUser,
  } = useInstruction(isCreateCard, false)
  const amount = isCreateCard ? cryptoAmount : totalAmount

  const unblock = history.block((params) => {
    if (isOnrampUser) return true as unknown as void
    setShowDialog(params.pathname)
    if (block.current) return false
    return true as unknown as void
  })

  const onleave = (e: FocusEvent) => {
    e.preventDefault()
  }
  const onUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault()
  }
  useEffect(() => {
    if (!isOnrampUser) {
      window.addEventListener("blur", onleave)
      window.addEventListener("beforeunload", onUnload)
    }
    return () => {
      unblock()
      window.removeEventListener("blur", onleave)
      window.removeEventListener("beforeunload", onUnload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <InstructionDialog
        showDialog={Boolean(showDialog)}
        onCancel={() => setShowDialog("")}
        onLeave={() => {
          block.current = false
          history.push(showDialog)
        }}
      />
      <div className="row">
        <div className="col-md-6">
          <div className="octagon octagon-whit block-table-octagon">
            <div className="warning-info" style={{ fontSize: 14 }}>
              <img src={warningIcon} alt="" />
              <span>
                Send the exact amount of {isCreateCard ? cryptoAmount : totalAmount} {crypto} to the{" "}
                {crypto} address specified in one transaction.
              </span>
            </div>
            <div className="block-table-octagon_text mt30">
              <p>We do not process different amounts or payments in multiple transactions.</p>
            </div>
            {isOnrampUser && (
              <div
                className="block-table-octagon_text mt20 d-flex align-items-center"
                style={{ gap: 8 }}
              >
                <OnRampIcon />
                <p>To make a payment you will be redirected to Onramp Money.</p>
              </div>
            )}
            <div className="block-table-octagon_text mt20">
              <p>Please read our cryptocurrency processing set of rules:</p>
              <ul>
                {!isOnrampUser && (
                  <li>
                    You can use one cryptocurrency address for only one deposit. You can't use this
                    wallet again. Please Top-Up by Crypto step when starting a new deposit.
                  </li>
                )}
                <li>
                  When you start a transaction, the exchange rate is fixed for 15 minutes, during
                  this time the transaction has to receive 1 confirmation at the Blockchain network.
                </li>
                <li>
                  Please contact customer support if within 15 minutes the transaction didn’t
                  receive any confirmation or if you made payment later for refund or manual
                  processing.
                  <br />
                  Please note that the exchange rate may change from the original.
                </li>
                <li>We monitor every crypto transaction according to AML regulations.</li>
              </ul>
            </div>
            <div className="block-table-octagon_text mt40">
              <p>
                When the transaction receives more than 6 Blockchain network confirmations, the
                deposit will be credited to your card.{" "}
              </p>
              <p>A refund is possible if the payment received within 3 days. </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div
            className={`instruction-qr-code octagon octagon-whit block-table-octagon${
              !isOnrampUser ? " block-qr-code" : ""
            }`}
          >
            {isOnrampUser ? (
              <div style={{ width: "100%", textAlign: "left" }}>
                <h6>Payment Confirmation</h6>
                <p>
                  Amount: {deposit} {fiat}
                </p>
                <p style={{ margin: "10px 0" }}>
                  You spend: {amount} {crypto}
                </p>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  Warning:
                  <div style={{ color: "red" }}>
                    <components.Timer
                      enabled
                      format="MM:SS"
                      count_down={900}
                      onUpdate={fetchDepositAddress}
                      secText="min"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="block-qr-code_text">
                {!loading ? (
                  <>
                    <div>
                      <img
                        src={QRCode}
                        className="block-qr-code_img"
                        alt=""
                        style={{ display: "inline-block" }}
                      />
                      <div className="block-qr-code_val mt-20 d-flex justify-content-center">
                        {amount} {crypto}
                        <div
                          className="btn-copy"
                          style={{ margin: 0, marginLeft: 12 }}
                          onClick={() => copyToClipboard(amount)}
                        />
                      </div>
                      <div className="block-qr-code_val-from">
                        {deposit} {fiat}
                      </div>
                      <div className="btn-copy" onClick={() => copyToClipboard(wallet)} />
                      <div className="block-qr-code_code">{wallet}</div>
                      <div style={{ color: "#777e8aDD", fontSize: 14, paddingTop: 8 }}>
                        <components.Timer
                          enabled={wallet != null}
                          format="MM:SS"
                          count_down={900}
                          onUpdate={fetchDepositAddress}
                          secText="min"
                        />
                      </div>
                    </div>
                    {crypto === "USDT" && (
                      <span className="note-tip">
                        Note! We accept only USDT ERC20 token standard{" "}
                      </span>
                    )}
                  </>
                ) : (
                  <h6>Loading...</h6>
                )}
              </div>
            )}
          </div>
        </div>

        <ul className="outer-tip">
          <li>Please don't forget to press button after sending crypto</li>
        </ul>
        <div className="bottom-line-btn d-flex align-items-center instruction-page-style">
          <div className="instruction-actions">
            <ul className="inner-tip">
              {isOnrampUser ? (
                <li>You will be redirected to Onramp Money to make a payment</li>
              ) : (
                <li>Please don't forget to press button after sending crypto</li>
              )}
            </ul>
            <button
              className="btn-reset btn-sq btn-long btn-red"
              color="Blue"
              onClick={() => {
                window.removeEventListener("blur", onleave)
                block.current = false
                createDeposit()
              }}
            >
              {createLoading ? "Loading..." : isOnrampUser ? "PROCEED" : "I HAVE PAID"}
            </button>
          </div>
          <BackButton
            className="to_dashboard"
            text="Go to dashboard"
            onClick={() => history.push("/client")}
          />
        </div>
      </div>
    </>
  )
}

export default Instructions
