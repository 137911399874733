import React, { FC, InputHTMLAttributes } from "react"
import { useFormContext } from "react-hook-form"
import "@/Components/CheckBox/index.sass"

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  refProps?: object
  required?: boolean
}

export const CtrlRadio: FC<RadioProps> = ({
  name,
  value,
  label,
  refProps,
  required,
  children,
  ...rest
}) => {
  const { register, errors } = useFormContext()

  if (required) refProps = { ...refProps, required: "This field is required." }

  return (
    <>
      <input
        type="radio"
        name={name}
        id={`${name}_${value}`}
        value={value}
        className="Form-Checkbox"
        ref={register(refProps)}
        {...rest}
      />
      {children && <label htmlFor={`${name}_${value}`}>{children}</label>}
      {errors[name] && <div className="invalid-feedback">{errors[name].message}</div>}
    </>
  )
}
