import { Image } from "@/Components/common/Image"

export const Access = () => {
  return (
    <section className="secure">
      <div className="container">
        <div className="secure__wrap">
          <h2 className="secure__title h2">
            ACCESS YOUR ASSETS SIMPLE SECURE🔒, FAST AND TRUSTWORTHY
          </h2>
          <ul className="secure-list">
            <li className="secure-list__item">
              <Image src="cards-1.png" />
              <h4 className="secure-list__title">Chip & pin access</h4>
              <div className="secure-list__text">
                EMV chip, PIN code and all the specifications provide fast payments, as well as
                security up to the highest world standards.
              </div>
            </li>
            <li className="secure-list__item">
              <Image src="cards-2.png" />
              <h4 className="secure-list__title">Forget your interest charges</h4>
              <div className="secure-list__text">
                All fees are stated before you proceed with the transaction. No hidden fees &
                surprises
              </div>
            </li>
            <li className="secure-list__item">
              <Image src="cards-3.png" />
              <h4 className="secure-list__title">Low commission rate</h4>
              <div className="secure-list__text">
                All fees are stated before you proceed with the transaction. No hidden fees &
                surprises
              </div>
            </li>
            <li className="secure-list__item">
              <Image src="cards-4.png" />
              <h4 className="secure-list__title">Worldwide deliveries with no delays</h4>
              <div className="secure-list__text">
                All fees are stated before you proceed with the transaction. No hidden fees &
                surprises
              </div>
            </li>
            <li className="secure-list__item">
              <Image src="cards-5.png" />
              <h4 className="secure-list__title">Have absolute control</h4>
              <div className="secure-list__text">
                All fees are stated before you proceed with the transaction. No hidden fees &
                surprises
              </div>
            </li>
            <li className="secure-list__item">
              <Image src="cards-6.png" />
              <h4 className="secure-list__title">Notifications to improve our service</h4>
              <div className="secure-list__text">
                All fees are stated before you proceed with the transaction. No hidden fees &
                surprises
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}
