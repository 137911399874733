import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { FormProvider } from "react-hook-form"
import { CtrlSelect } from "@/Components/ctrl/Select"
import { CtrlInput } from "@/Components/ctrl/Input"
import {
  CARD_TYPE_VIRTUAL,
  selectClientCards,
  useCardCreate,
  useStoreClient,
  useTUCardStore,
} from "@usher/pe-client-front-end-lib"
import { useCreateCardPhysicalMutation, useCreateCardVirtualMutation } from "@/gql/types"
import { ExpensePinCode } from "./PinCode"
import { ExpenseDeliveryAddress } from "./DeliveryAddress"
import { Expense3DSPassword } from "./3DSPassword"
import back from "@/Images/cards_images/back.svg"
import shallow from "zustand/shallow"
import { gtag } from "@/Helpers/gTag"

export const CreateForm = () => {
  const history = useHistory()
  const [accepted, setAccepted] = useState(false)
  const [useAccepted, setUseAccepted] = useState(false)
  const [isUs, setIsUs] = useState(false)
  const { isAllExist, cardTypes: existedTypes } = useStoreClient(selectClientCards, shallow)
  const { onSubmit, formMethods, isVirtual, cardTypes } = useCardCreate({
    physicalCard: {
      mutation: useCreateCardPhysicalMutation,
    },
    virutalCard: {
      mutation: useCreateCardVirtualMutation,
    },
    onSuccess: (type) => {
      gtag("event", type)()
      history.push("/client/cards/order-card/expense/select-payment")
    },
  })
  const setValue = formMethods.setValue
  useEffect(() => {
    if (window.location.host !== "wizarbit.com") return
    setTimeout(() => {
      setValue("type", CARD_TYPE_VIRTUAL)
    }, 10)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const id = useTUCardStore((state) => state.cardTypeId)
  return (
    <>
      <div className="main-form-cards_card fit-content">
        <>
          <div
            style={{
              fontSize: 14,
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: 30,
            }}
          >
            Expenses Card {id}
            <p style={{ fontSize: 12 }}>Mastercard for your expenses</p>
          </div>

          <FormProvider {...formMethods}>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                if (accepted && useAccepted && isUs) formMethods.handleSubmit(onSubmit)()
              }}
              className="main-form-cards_form"
            >
              <div className="mb40">
                <CtrlInput name="username" className="mb10 disabled" label="User Name" disabled />
                <CtrlInput name="nickname" className="mb10" label="Card Nickname (optional)" />
                <div className="Form-InputBox Form-InputBox--Dark">
                  <span className="Form-Input__Label">Type Card</span>
                  <div
                    className={`select-form select-card mb10 ${
                      isVirtual ? "select-card-green" : ""
                    }`}
                  >
                    <CtrlSelect
                      disabled={isAllExist}
                      name="type"
                      options={cardTypes.filter(
                        (type) =>
                          !existedTypes.includes(type) &&
                          (window.location.host === "wizarbit.com" ? type !== "Physical" : true)
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="mb40">
                {!isVirtual && (
                  <>
                    <ExpensePinCode />
                    <ExpenseDeliveryAddress />
                    {/* <ExpenseDeliveryOptions options={deliveryTypes} /> */}
                  </>
                )}
                {isVirtual && <Expense3DSPassword />}
              </div>
              <div onClick={() => setAccepted(!accepted)}>
                <input type="checkbox" className="input-checkbox" checked={accepted} readOnly />
                <label className="label-checkbox mb15">
                  <span>
                    I accept{" "}
                    <a
                      style={{ display: "inline", color: "#5CA1FF" }}
                      onClick={(e) => e.stopPropagation()}
                      href="/en/card-terms"
                      target="_blank"
                    >
                      Card Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      style={{ color: "#5CA1FF", display: "inline" }}
                      onClick={(e) => e.stopPropagation()}
                      href="/card-framework"
                      target="_blank"
                    >
                      Card Framework Agreement
                    </a>
                  </span>
                </label>
              </div>
              <div onClick={() => setUseAccepted(!useAccepted)}>
                <input type="checkbox" className="input-checkbox" checked={useAccepted} readOnly />
                <label className="label-checkbox mb15">
                  <span>
                    I agree with{" "}
                    <a
                      style={{ display: "inline", color: "#5CA1FF" }}
                      onClick={(e) => e.stopPropagation()}
                      href="/en/card-use-terms"
                      target="_blank"
                    >
                      Wizarbit's Terms of use for cards
                    </a>
                  </span>
                </label>
              </div>
              <div style={{ marginBottom: 40 }} onClick={() => setIsUs(!isUs)}>
                <input type="checkbox" className="input-checkbox" checked={isUs} readOnly />
                <label className="label-checkbox mb15">
                  <span>I am not a US citizen or resident</span>
                </label>
              </div>
              <div className="d-flex actions gap-20">
                <Link to="/client/cards" className="btn-sq flex-1 btn-go-back">
                  <img src={back} alt="" />
                  GO BACK
                </Link>
                {!isAllExist && (
                  <input
                    type="submit"
                    value="Next"
                    className={`btn-reset btn-sq btn-red no-decorate flex-1 ${
                      accepted && useAccepted && isUs ? "" : "btn-disabled"
                    }`}
                  />
                )}
              </div>
            </form>
          </FormProvider>
        </>
      </div>
    </>
  )
}
