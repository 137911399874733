import React from "react"
import classnames from "classnames"
import { Line } from "react-chartjs-2"
import NumberFormat from "react-number-format"
import { useCryptoStocks } from "@usher/pe-client-front-end-lib"
import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
} from "chart.js"
import FormSelect from "@/Components/FormSelect"
import { IconStatsDown, IconStatsUp } from "@/Images/Icons"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler)

if (typeof window !== "undefined") {
  window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill
}

const TIME_FRAMES = ["1 hour", "1 day", "1 week", "1 month", "1 year"]

export const DashboardChart = () => {
  const {
    typeId,
    setTypeId,
    setCryptoSymbol,
    fiatSymbol,
    cryptoSymbol,
    setFiatSymbol,
    chart,
    rate,
    options,
  } = useCryptoStocks({ defaultCrypto: "BTC", defaultFiat: "EUR" })

  return (
    <div className="ToolboxesChart">
      <div className="ToolboxesChart__Head">
        <div className="ToolboxesChart__Currencies">
          <div className="ToolboxesChart__SmallSelect">
            <FormSelect
              type="Simple"
              options={options.crypto.map((opt: any) => ({
                label: opt,
                value: opt,
              }))}
              value={{ label: cryptoSymbol, value: cryptoSymbol }}
              theme="Dark"
              size="Small"
              handleChange={(val: any) => setCryptoSymbol(val.value)}
            />
          </div>
          <div className="ToolboxesChart__Select">
            <span>
              <NumberFormat
                value={rate.value}
                displayType="text"
                decimalScale={2}
                thousandSeparator={true}
              />
            </span>
            <FormSelect
              type="Simple"
              options={options.fiat.map((opt: any) => ({
                label: opt,
                value: opt,
              }))}
              value={{ label: fiatSymbol, value: fiatSymbol }}
              theme="Dark"
              handleChange={(val: any) => setFiatSymbol(val.value)}
            />
          </div>
        </div>
        <div className="ToolboxesChart__Info">
          <div className="ToolboxesChart__Label">CHANGED SINCE LAST DAY:</div>
          <div className="ToolboxesChart__Value">
            <span>
              {Math.abs((rate.value * rate.change) / 100).toFixed(2)} {fiatSymbol}
            </span>
            <div
              className={classnames("ToolboxesChart__Rate", {
                ToolboxesChart__Rate_Up: rate.change >= 0,
              })}
            >
              {rate.change < 0 ? <IconStatsDown /> : <IconStatsUp />}
              <span>{rate.change}%</span>
            </div>
          </div>
        </div>
      </div>
      <div className="ToolboxesChart__TimeFrame">
        {TIME_FRAMES.map((name, key) => (
          <button
            key={key}
            onClick={() => setTypeId(key)}
            className={classnames({ active: key === typeId })}
          >
            {name}
          </button>
        ))}
      </div>
      <div className="ToolboxesChart__Graph">
        <Line
          options={{
            responsive: true,
            interaction: {
              mode: "index" as const,
              intersect: false,
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                grid: {
                  display: false,
                },
              },
            },
          }}
          data={{
            labels: chart.labels,
            datasets: [
              {
                type: "line",
                pointRadius: 3,
                fill: true,
                backgroundColor: "rgba(243,248,255,1)",
                borderColor: "#55aaff",
                borderWidth: 1,
                data: chart.data,
              },
            ],
          }}
        />
      </div>
    </div>
  )
}
