import './index.sass'
import {NavLink} from "react-router-dom";
import classnames from "classnames";
import { Msg } from '../Msg';

export const Nav = ({ theme, data, handleClick, activeId }) => {
    return (
        <div className={classnames('Nav', {
            [`Nav--${theme}`]: theme
        })}>
            <nav className="Nav__Menu">
                <ul className="Nav__List">
                    { data.map(item => (
                        <li className="Nav__Item" key={item.id}>
                            {item.href ? (
                                <NavLink
                                    className="Nav__Link"
                                    to={item.href}
                                    activeClassName="Nav__Link--Active"
                                >
                                    <Msg id={item.text} />
                                </NavLink>
                            ) : (
                                <span onClick={() => handleClick(item.id)}
                                      className={classnames({ 'active': item.id === activeId })}
                                >{item.text || item.name}</span>
                            )}
                    </li>))}
                </ul>
            </nav>
        </div>
    )
};
