import React, { useEffect, useLayoutEffect, useRef } from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"
import { AutoLogout, useClientApp, useStoreClient } from "@usher/pe-client-front-end-lib"
import { DashboardAside } from "./DashboardAside"
import { Toolboxes } from "./Toolboxes"
import { Transactions } from "./Transactions"
import { Verifications } from "./Verifications"
import { Settings } from "./Settings"
import "./index.sass"
import { OrdersPage } from "./Orders"
import { Provider } from "urql"
import { CardsPage } from "./Cards"
import TawkMessenger from "@tawk.to/tawk-messenger-react"
import "@/Components/Box/index.sass"
import { setCookieConsentBottom } from "@/utils"

export const Dashboard = () => {
  const path = useRouteMatch().path
  const ref = useRef()
  const fetchCards = useStoreClient((state) => state.fetchClientCards)
  const fetchAtiveCards = useStoreClient((state) => state.fetchActiveCards)
  useEffect(() => {
    const fetchCallback = () => {
      fetchCards(true)
      fetchAtiveCards(true)
    }
    window.addEventListener("focus", fetchCallback)
    return () => {
      window.removeEventListener("focus", fetchCallback)
    }
  }, [])
  useLayoutEffect(() => {
    if (window.innerWidth < 768) setCookieConsentBottom(75)
    return () => setCookieConsentBottom(0)
  }, [])
  useEffect(() => {
    window.Tawk_API?.showWidget()
    return () => {
      window.Tawk_API?.hideWidget()
    }
  }, [])

  const { isInitialized, client, isCardActive } = useClientApp(true)
  if (!isInitialized) return null

  return (
    <>
      <AutoLogout timeout={30} />
      <div className="tawkaka">
        <TawkMessenger
          ref={ref}
          propertyId="65d329a19131ed19d96e88b5"
          widgetId="1hn0dl89c"
          customStyle={{
            visibility: {
              mobile: {
                xOffset: 20,
                yOffset: 20,
                position: "cr",
              },
            },
          }}
        />
      </div>
      <Provider value={client}>
        <div className="Dashboard">
          <div className="Dashboard__Aside">
            <DashboardAside />
          </div>
          <div className="Dashboard__Content">
            <Switch>
              <Route exact path={path} component={Toolboxes} />
              <Route path={`${path}/exchange`} component={OrdersPage} />
              <Route path={`${path}/orders`} component={Transactions} />
              <Route path={`${path}/verifications`}>
                <Verifications path={path} />
              </Route>
              <Route path={`${path}/settings`} component={Settings} />
              {isCardActive && <Route path={`${path}/cards`} component={CardsPage} />}
            </Switch>
          </div>
        </div>
      </Provider>
    </>
  )
}
