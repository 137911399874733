export interface ILimitItem {
  label: string
  icon?: string
  rightValue: {
    label: string
    value: string
    icon?: string
  }
}
export const LIMITS_ITEMS: ILimitItem[] = [
  {
    label: "BTC",
    rightValue: {
      label: "ETH",
      value: "ETH",
    },
  },
  {
    label: "ETH",
    rightValue: {
      label: "BTC",
      value: "BTC",
    },
  },
  {
    label: "LTC",
    rightValue: {
      label: "BTC",
      value: "BTC",
    },
  },
  {
    label: "USDT",
    rightValue: {
      label: "ETH",
      value: "ETH",
    },
  },
  {
    label: "USDT-TRC20",
    icon: "usdt",
    rightValue: {
      label: "LTC",
      value: "LTC",
    },
  },
]
