import { Text } from "@/Components/Text"
import { Container } from "@/Layout/Container"
import { Link } from "react-router-dom"
import "./style.css"
import { Paper } from "@/Layout/Paper"
import { Input } from "@/Components/Form/Input"
import { FormWrapper } from "@/Components/Form"
import {
  VALID_EMAIL,
  VALID_PASSWORD,
  VALID_PHONE,
  useRegisterForm,
} from "@usher/pe-client-front-end-lib"
import { Checkbox } from "@/Components/Form/Checkbox"
import { CtrlReCaptcha } from "@/Components/ctrl/ReCaptcha"
import { Button } from "@/Components/Control/Button"
import { Msg } from "@/Components/Msg"
import { VerifyModal } from "./VerifyModal"
import { AgreeAgeCheck, AgreeRefundCheck, AgreeRiskCheck, AgreeTermCheck } from "./Checkboxes"
import { useIntl } from "react-intl"
import { CountrySelect } from "@/NewComponents/CountrySelect"
import { Stack } from "@/Layout/Stack"

interface IRegisterFormProps {
  isForCard?: boolean
}
export const Register = ({ isForCard = false }: IRegisterFormProps) => {
  const intl = useIntl()
  const { captchaRef, loading, formMethods, modalProps, onSubmit, modalOnClose, onCaptchaChange } =
    useRegisterForm()
  return (
    <div className="register_wrapper">
      <VerifyModal {...modalProps} handleClose={modalOnClose} />
      <Container>
        {!isForCard && (
          <div className="register_form_head">
            <Text>
              <Msg id="registration" />
            </Text>
            <Text variant="subtitle">
              <Msg id="registered" />?{" "}
              <Link to="/login">
                <Msg id="login" />
              </Link>
            </Text>
          </div>
        )}
        <FormWrapper noValidate methods={formMethods} onSubmit={onSubmit}>
          <Paper>
            <div className="register__grid">
              <Input
                dark={isForCard}
                label={intl.formatMessage({ id: "fname" })}
                name="first_name"
                required
              />
              <CountrySelect
                isDark={isForCard}
                name="national"
                required
                label={intl.formatMessage({ id: "national" })}
              />
              <Input
                dark={isForCard}
                label={intl.formatMessage({ id: "lname" })}
                name="last_name"
                required
              />
              <CountrySelect
                isDark={isForCard}
                name="country_code"
                required
                label={intl.formatMessage({ id: "Country of Residence" })}
              />
              <Input
                dark={isForCard}
                rules={VALID_EMAIL}
                label={intl.formatMessage({ id: "your_email" })}
                type="email"
                isEmail
                name="email"
                required
              />
              <Input
                dark={isForCard}
                label={intl.formatMessage({ id: "state" })}
                name="state"
                required
              />
              <Input
                dark={isForCard}
                rules={{ ...VALID_PHONE, minLength: undefined, maxLength: undefined }}
                label={intl.formatMessage({ id: "phone" })}
                name="phone"
                required
              />
              {isForCard ? (
                <Stack mb={0} gap={20}>
                  <Input
                    dark={isForCard}
                    rules={VALID_PASSWORD}
                    label={intl.formatMessage({ id: "pass" })}
                    type="password"
                    name="password"
                    required
                  />
                  <Input
                    dark={isForCard}
                    rules={VALID_PASSWORD}
                    label={intl.formatMessage({ id: "cpass" })}
                    type="password"
                    name="password2"
                    required
                  />
                </Stack>
              ) : (
                <>
                  <Input
                    dark={isForCard}
                    label={intl.formatMessage({ id: "city" })}
                    name="city"
                    required
                  />
                  <Input
                    dark={isForCard}
                    rules={VALID_PASSWORD}
                    label={intl.formatMessage({ id: "pass" })}
                    type="password"
                    name="password"
                    required
                  />
                  <Input
                    dark={isForCard}
                    label={intl.formatMessage({ id: "saddress" })}
                    name="address"
                    required
                  />
                  <Input
                    dark={isForCard}
                    rules={VALID_PASSWORD}
                    label={intl.formatMessage({ id: "cpass" })}
                    type="password"
                    name="password2"
                    required
                  />
                  <Input
                    dark={isForCard}
                    label={intl.formatMessage({ id: "apt" })}
                    name="address2"
                    required
                  />
                </>
              )}

              <div className="select_sex">
                <Checkbox name="sex" value="1" required label={<Msg id="male" />} type="radio" />
                <Checkbox name="sex" value="0" required label={<Msg id="female" />} type="radio" />
              </div>
              {!isForCard && (
                <Input
                  dark={isForCard}
                  label={intl.formatMessage({ id: "zip" })}
                  name="zip"
                  required
                />
              )}
            </div>
            <div className="policy">
              <AgreeTermCheck />
              <AgreeRiskCheck />
              <AgreeRefundCheck />
              <AgreeAgeCheck />
            </div>

            <CtrlReCaptcha refObj={captchaRef as never} onChange={onCaptchaChange} />
            <Button loading={loading} style={{ marginTop: 49 }} type="submit">
              <Msg id="registration" />
            </Button>
          </Paper>
        </FormWrapper>
      </Container>
    </div>
  )
}
