import React, { useState } from "react"
import { useStoreClient, Verification } from "@usher/pe-client-front-end-lib"
import { Modal } from "@/Components/Modal"
import { VerifyItem } from "./Item"
import { VerifyItemHelp } from "./ItemHelp"
import { VerifyUpload } from "./Upload"
import shallow from "zustand/shallow"

export const VerificationsVerify = () => {
  const { verify_flags, uploadedTypes } = useStoreClient(
    (state) => ({
      verify_flags: state.user.verify_flags,
      uploadedTypes: state.uploaded_types,
    }),
    shallow
  )
  const [verifyType, setVerifyType] = useState("0")
  const verifyFlags = parseInt(verify_flags)
  return (
    <>
      <VerifyItem
        onClick={() => setVerifyType("1")}
        verified={verifyFlags & Verification.VERIFICATION_PASSPORT_EXCLIENT}
        isUploaded={{ uploadedTypes, section: 1 }}
      >
        <span>ID / Passport</span>
        <VerifyItemHelp id={0}>
          <p>
            Photo / scan of the passport (quality so that we can see all the records and numbers)
          </p>
          <p>
            The passport or ID card provided for verification must contain the following
            information:
          </p>
          <ul>
            <li>Country that issued the document</li>
            <li>The serial number of the document proving the identity</li>
            <li>Date of issue</li>
            <li>Gender</li>
            <li>Surname</li>
            <li>Name</li>
            <li>Date of birth</li>
            <li>Place of birth</li>
            <li>Date of expiry</li>
          </ul>
        </VerifyItemHelp>
      </VerifyItem>

      <VerifyItem
        onClick={() => setVerifyType("2")}
        verified={verifyFlags & Verification.VERIFICATION_CARD_EXCLIENT}
        isUploaded={{ uploadedTypes, section: 2 }}
      >
        Proof of address
        <VerifyItemHelp id={1}>
          Upload your address proof. It may be utility or electricity bill or a bank statement.
        </VerifyItemHelp>
      </VerifyItem>

      <VerifyItem
        onClick={() => setVerifyType("3")}
        verified={verifyFlags & Verification.VERIFICATION_UTILITY_BILL_EXCLIENT}
        isUploaded={{ uploadedTypes, section: 3 }}
      >
        Payment card
        <VerifyItemHelp id={2}>
          The copy of the card that you are paying with must include the first six and the last four
          digits of its number.
        </VerifyItemHelp>
      </VerifyItem>

      <VerifyItem
        onClick={() => setVerifyType("4")}
        verified={verifyFlags & Verification.VERIFICATION_SELFIE_PASSPORT_EXCLIENT}
        isUploaded={{ uploadedTypes, section: 4 }}
      >
        <span className="large-title">
          Selfie with passport/id in hand
          <VerifyItemHelp id={3}>Please upload your photo with ID</VerifyItemHelp>
        </span>
      </VerifyItem>

      {verifyType !== "0" && (
        <>
          <Modal isOpen={true} size="Large" handleClose={() => setVerifyType("0")}>
            {verifyType !== "0" && (
              <VerifyUpload isAllVerified={verifyFlags === 126} type={verifyType} />
            )}
          </Modal>
        </>
      )}
    </>
  )
}
