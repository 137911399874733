import { useMemo } from "react"
import { useLang } from "@/Layout/hooks/lang"
const FLAG_PATHS = {
  "lt-LT": "/img/countries/europe/lithuania.svg",
  "en-US": "/img/countries/europe/united-kingdom.svg",
}
export const LanguageSelect = () => {
  const { locale, langOptions, onLangChange } = useLang()
  const selectedLang = useMemo(
    () => langOptions.find((lang) => lang.value === locale),
    [locale, langOptions]
  )
  return (
    <div className="dropdown">
      <button
        className="language-select"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img
          src={FLAG_PATHS[locale]}
          style={{ display: "inline", width: 18, height: 18, marginRight: 6 }}
          alt={selectedLang.label}
        />
        <span className="Header__Link" style={{ margin: 0, pointerEvents: "none" }}>
          {selectedLang?.label ?? "English"}
        </span>
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        {langOptions.map((option) => (
          <li
            key={option.value}
            style={{ cursor: "pointer" }}
            onClick={() => onLangChange(option.value)}
          >
            <span className="dropdown-item">
              <img
                src={FLAG_PATHS[option.value]}
                style={{ display: "inline", width: 18, height: 18, marginRight: 6 }}
                alt={option.label}
              />
              {option.label}
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
}
