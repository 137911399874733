import React from "react"
import ReactTooltip from "react-tooltip"
import { IconInfo } from "@/Images/Icons"

type Props = {
  id: number
  children: React.ReactNode
}

export const VerifyItemHelp = ({ id, children }: Props) => {
  return (
    <span className="VerifyAccount__Item-Icon" data-for={`tooltip-${id}`} data-tip="">
      <IconInfo width="17" height="17" />
      <ReactTooltip
        className="TooltipSimple"
        id={`tooltip-${id}`}
        place={"bottom"}
        effect="solid"
        arrowColor="#5BA1FF"
        textColor="#fff"
        backgroundColor="#5BA1FF"
        offset={{ left: 105 }}
      >
        {children}
      </ReactTooltip>
    </span>
  )
}
