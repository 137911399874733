import { BSelect } from "@/NewComponents/BSelect"
import { ILimitItem } from "./limits"
import { Image } from "@/Components/common/Image"
import { useState } from "react"
const options = [
  { label: "BTC", value: "BTC" },
  { label: "ETH", value: "ETH" },
  { label: "LTC", value: "LTC" },
  { label: "USDT", value: "USDT" },
  { label: "USDT-TRC20", value: "usdt-trc20", icon: "usdt" },
  { label: "EUR", value: "EUR" },
  // { label: "USD", value: "USD" },
]

const getOptions = (data: ILimitItem) => {
  let exclude = [data.label]
  if (["ADVCASH"].includes(data.label)) exclude = [...exclude, "ADVCASH", "EUR", "USD"]

  return options.filter((o) => !exclude.includes(o.label))
}

const getRate = (data: ILimitItem, selectValue: ILimitItem["rightValue"]) => {
  if (["USDT-TRC20"].includes(data.label) || ["USDT-TRC20"].includes(selectValue.label)) return 8
  if (["EUR", "USD"].includes(selectValue.label)) return 10
  return 1.5
}

interface ILimitBlockProps {
  limit: ILimitItem
}
export const LimitBlock = ({ limit }: ILimitBlockProps) => {
  const options = getOptions(limit)
  const value = options.find((option) => limit.rightValue.label === option.label)
  const [selected, setSelected] = useState<ILimitItem["rightValue"]>(value)
  return (
    <li className="rates-list__item">
      <div className="rates-list__box">
        <Image src={`${limit.icon ?? limit.label.toLowerCase()}-dark.svg`} alt={limit.label} />
        <div className="rates-list__block">
          <span>1</span>
          <p>{limit.label}</p>
        </div>
      </div>
      <div className="rates-list__per">{getRate(limit, selected)}%</div>
      <div className="rates-list__box">
        <BSelect
          buttonStyle={{ minWidth: 180 }}
          renderOption={(option) => {
            const value = option as ILimitItem["rightValue"]
            return (
              <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
                <Image
                  src={`${value.icon ?? value.label.toLowerCase()}-dark.svg`}
                  alt={value.label}
                />
                {value.label}
              </div>
            )
          }}
          renderLabel={(option) => {
            const value = option as ILimitItem["rightValue"]
            return (
              <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
                <Image
                  src={`${value?.icon ?? value?.label.toLowerCase()}-dark.svg`}
                  alt={value?.label}
                />
                {value?.label}
              </div>
            )
          }}
          options={getOptions(limit)}
          onSelect={(value) => setSelected(value as ILimitItem["rightValue"])}
          isDark
          value={value}
        />
      </div>
    </li>
  )
}
