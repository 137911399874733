import React from "react"
import { useRouteMatch } from "react-router-dom"
import { selectUser, useStoreClient } from "@usher/pe-client-front-end-lib"
import Button from "@/Components/Button"

function InfoItem({ label, value }: { label: string; value: string | number }) {
  return (
    <div className="ToolboxesInfo__Item-Wrap">
      <div className="ToolboxesInfo__Item">
        <div className="ToolboxesInfo__Item-Label">{label}:</div>
        <div className="ToolboxesInfo__Item-Value">{value}</div>
      </div>
    </div>
  )
}

export const DashboardPersonalInfo = () => {
  const path = useRouteMatch().path
  const { first_name, last_name, id, country_name, phone, email, date_created, date_last_login } =
    useStoreClient(selectUser)

  return (
    <div className="ToolboxesInfo">
      <div className="ToolboxesInfo__Title">INFORMATION</div>
      <div className="ToolboxesInfo__Items">
        <InfoItem label="First Name" value={first_name} />
        <InfoItem label="Last Name" value={last_name} />
        <InfoItem label="ID" value={id} />
        <InfoItem label="Country" value={country_name} />
        <InfoItem label="Phone" value={phone} />
        <InfoItem label="Email" value={email} />
        <InfoItem label="Last login time" value={date_last_login} />
        <InfoItem label="Time of registration" value={date_created} />
      </div>
      <Button color="Red" to={`${path}/settings`} className="ToolboxesInfo__Button">
        Edit
      </Button>
    </div>
  )
}
