import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom"
import { CardsCreateType } from "./Type"
import { CardCreateExpense } from "./Expense"
import { CardCreateSalary } from "./Salary"
import "./style.sass"
import { Modal } from "@/Components/Modal"
import { selectUser, useStoreClient } from "@usher/pe-client-front-end-lib"

export const CardsCreate = () => {
  const history = useHistory()
  const { verify_flags } = useStoreClient(selectUser)
  const { path } = useRouteMatch()
  return (
    <main className="main-form-cards" style={{ backgroundColor: "transparent" }}>
      {(verify_flags as number) < 126 && (
        <Modal size={1} handleClose={() => history.goBack()} isOpen={true}>
          <div className="Modal__Title">Order Card</div>
          <div className="Modal__Description">
            To initiate a card order, kindly ensure that your accout is verified.
            <br />
            For verification, please get in touch with our Compliance Department at verify{" "}
            <a href="verify@wizarbit.com">verify@wizarbit.com</a>.
          </div>
        </Modal>
      )}
      <div className="row">
        <div className="col-md-12">
          <Switch>
            <Route path={path} exact>
              <CardsCreateType />
            </Route>
            <Route path={`${path}/expense`}>
              <CardCreateExpense />
            </Route>
            <Route path={`${path}/salary`}>
              <CardCreateSalary />
            </Route>
          </Switch>
        </div>
      </div>
    </main>
  )
}
