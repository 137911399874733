import './index.sass'
import React from 'react';
import classnames from 'classnames';

export default class Input extends React.Component {
    handlePress(event) {
        if (this.props.isnumbersonly) {
            // eslint-disable-next-line
            if (!/[/^\d{1,}(\.\d{0,4})?$/]/.test(event.key)) {
                return event.preventDefault();
            }
        }
    }

    render() {
        const { children, value, type = 'text', placeholder = ' ', label, theme, handleChange, withSelect, emptyLabel, ...rest } = this.props;

        return (
            <div
                className={classnames('Form-InputBox', `Form-InputBox--${theme}`)}
            >
                { (label && placeholder.length < 2) &&
                    <span className="Form-Input__Label">{ label }</span>
                }
                {emptyLabel && (<span className="Form-Input__Label">&nbsp;</span>)}
                <input
                    className={classnames(
                        'Form-Input',
                        {
                            [`Form-Input--${theme}`]: theme,
                            [`Form-Input--WithSelect`]: withSelect
                        }
                    )}
                    onKeyPress={ (event) => this.handlePress(event) }
                    placeholder={ placeholder }
                    type={ type }
                    onChange={ (e) => handleChange(e) }
                    value={ value }
                    {...rest}
                />
                { children }
            </div>
        );
    }
}
